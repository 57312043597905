import { Color } from '@/theme/partials/palette.css';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import { CommonLinkId, CommonLinks } from '../getters/getCommonLinks';
import { filterBlocks, parseSanitisedBlocks } from '../parsers/blocks';
import { findOppositeColorScheme } from '../parsers/common';
import { Entry, isEntry } from '../parsers/entries';
import { appContext } from './context';

export const useView = (): Entry | null => {
  const { view } = React.useContext(appContext) ?? {};
  return view || null;
};

export const useAppLink = <T extends CommonLinkId>(typeId?: T): CommonLinks[T] | undefined => {
  const { commonLinks } = React.useContext(appContext) ?? {};

  return commonLinks && typeId ? commonLinks[typeId] : undefined;
};

export const useViewBlocks = () => {
  const { view } = React.useContext(appContext) ?? {};

  const blocks = maybeGet(view, 'blocks') ?? [];

  // FIXME: temporary fix
  const parsedBlocks = parseSanitisedBlocks(blocks as any);

  return filterBlocks(parsedBlocks);
};

export const useViewColorScheme = (
  maybeColorScheme?: Color
): { colorScheme: Color; oppositeScheme: Color } => {
  const defaultScheme = 'primary' as Color;
  const view = useView();
  const colorScheme = maybeGet(view, 'colorScheme');

  if (maybeColorScheme) {
    return {
      colorScheme: maybeColorScheme,
      oppositeScheme: findOppositeColorScheme(maybeColorScheme),
    };
  }

  if (isEntry(view, 'index'))
    return { colorScheme: defaultScheme, oppositeScheme: findOppositeColorScheme(defaultScheme) };

  return {
    colorScheme: colorScheme ?? defaultScheme,
    oppositeScheme: findOppositeColorScheme(colorScheme ?? defaultScheme),
  };
};

export const useProgramColorScheme = (): { colorScheme: Color; oppositeScheme: Color } => {
  // Mainly used for events hence secondary as the default scheme
  const defaultScheme = 'secondary';
  const view = useView();
  const program = maybeGet(view, 'programCategory');
  const colorScheme = maybeGet(program, 'colorScheme');

  const oppositeScheme = findOppositeColorScheme(colorScheme ?? defaultScheme);

  return {
    colorScheme,
    oppositeScheme,
  };
};

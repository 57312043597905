import { createPropSanitiser, SanitiserTypes, Typename } from '@liquorice/allsorts-craftcms-nextjs';
import { toBoolean, toId, toString, toStringOrNull, trimSlashes } from '@liquorice/utils';
import { BlockFragments } from '../parsers/blocks';
import { CategoryFragments } from '../parsers/categories';
import {
  DocumentFragmentTypes,
  parseColorScheme,
  parseDateRange,
  parseDocumentFragment,
  parseDocumentManyFragment,
  parseLinkFieldFragment,
  parseLinkFieldMultipleFragment,
  parsePagesTree,
  parseSocialLinksFragment,
  SocialLinkFragmentTypes,
} from '../parsers/common';
import { parseAddress } from '../parsers/common/parseAddress';
import { parseEventInfoFragment } from '../parsers/common/parseEventInfo';
import { parseEventPriceFragment } from '../parsers/common/parseEventPrice';
import { parseEventTimeFragment } from '../parsers/common/parseEventTime';
import { parseStatus } from '../parsers/common/parseStatus';
import { EntryFragments } from '../parsers/entries';
import { GlobalFragments } from '../parsers/globals';
import { ImageFragmentTypes, parseImageFragment, parseImageManyFragment } from '../parsers/images';
import {
  parseSeoJsonLd,
  parseSeoMetaLinks,
  parseSeoMetaTags,
  parseSeoScripts,
  parseSeoSiteVars,
  parseSeoTitle,
} from '../parsers/seo';
import { cleanHtml } from '../utils/htmlHelpers';
import { parseFormieForm } from '../parsers/form';

export type ElementTypes =
  | EntryFragments
  | GlobalFragments
  | SocialLinkFragmentTypes
  | DocumentFragmentTypes
  | ImageFragmentTypes
  | CategoryFragments
  | BlockFragments;

/**
 * __typename of top level Elements
 */
type ElementTypename = Typename<ElementTypes>;

const elementSanitiser = createPropSanitiser((): ElementTypes | null => null, {
  content: cleanHtml,
  heading: toStringOrNull,
  id: toId,
  label: toStringOrNull,
  venueShorthand: toStringOrNull,
  duration: toStringOrNull,
  caption: toStringOrNull,
  sectionHandle: toStringOrNull,
  enabled: toBoolean,
  status: parseStatus,

  entryTitle: toStringOrNull,
  entrySubTitle: toStringOrNull,
  entrySummary: toStringOrNull,
  title: toStringOrNull,
  uri: (value?: string | null) => (value ? `/${trimSlashes(value)}/` : ''),
  slug: toString,
  ancestors: 'sanitise',
  groupHandle: toString,
  eventId: toId,

  form: parseFormieForm,
  externalUrl: toStringOrNull,
  linkField: parseLinkFieldFragment,
  linkFieldMultiple: parseLinkFieldMultipleFragment,
  flipAlignment: toBoolean,
  latestEvents: toBoolean,
  lightswtich: toBoolean,
  onSale: toBoolean,
  colorScheme: parseColorScheme,
  date: toStringOrNull,
  dateRange: parseDateRange,
  facebook: toStringOrNull,
  twitter: toStringOrNull,
  instagram: toStringOrNull,
  pagesBranch: parsePagesTree,

  entryImage: parseImageFragment,
  imageSingle: parseImageFragment,
  imageMultiple: parseImageManyFragment,
  fileSingle: parseDocumentFragment,
  fileMultiple: parseDocumentManyFragment,

  socialLinks: parseSocialLinksFragment,

  eventTime: parseEventTimeFragment,
  eventInfo: parseEventInfoFragment,
  eventPrice: parseEventPriceFragment,

  address: parseAddress,

  seoJson: 'sanitiseSingle',
  metaLinkContainer: parseSeoMetaLinks,
  metaTagContainer: parseSeoMetaTags,
  metaTitleContainer: parseSeoTitle,
  metaScriptContainer: parseSeoScripts,
  metaJsonLdContainer: parseSeoJsonLd,
  metaSiteVarsContainer: parseSeoSiteVars,

  /**
   * Recursively sanitise child elements
   */
  // children: 'sanitise',
  blocks: 'sanitise',

  // Entries
  entrySingle: 'sanitiseSingle',
  eventSingle: 'sanitiseSingle',
  articleSingle: 'sanitiseSingle',
  venueSingle: 'sanitiseSingle',
  articleMultiple: 'sanitise',
  eventMultiple: 'sanitise',
  faqMultiple: 'sanitise',

  // Categories
  venueCategory: 'sanitiseSingle',
  genreCategory: 'sanitise',
  programCategory: 'sanitiseSingle',
  accessibilityCategory: 'sanitise',
  eventTypeCategory: 'sanitiseSingle',
});

export default elementSanitiser;

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

type SanitiserReturnMap = SanitiserTypes<typeof elementSanitiser, 'ReturnMap'>;

export type SanitisedElement<T extends ElementTypename = ElementTypename> = SanitiserReturnMap[T];

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

export const isSanitisedElement = <T extends ElementTypename>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  x: any,
  typename: T
): x is SanitisedElement<T> => {
  return !!x && x.__typename === typename;
};

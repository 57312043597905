import {
  deleteUndefined,
  firstNonNullable,
  makeNonNullableArray,
  toString,
  toStringOrNull,
} from '@liquorice/utils';
import { DocumentAsset } from './parseDocument';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { LINKFIELD_FRAGMENT } from '@/gql/fragments/linkField.gql';
import { LINKFIELD_MULTIPLE_FRAGMENT } from '@/gql/fragments/linkFieldMultiple.gql';
import { LinkFieldFragment, LinkFieldMultipleFragment } from '__generated__/graphql';
import { PossibleTypes } from '@liquorice/types';

export interface Link extends Pick<IntrinsicProps<'a'>, 'target' | 'title'> {
  href: string;
  text?: string;
  children?: string;
  download?: boolean;
  file?: DocumentAsset;
}

export type LinkFieldFragmentTypes = PossibleTypes<LinkFieldFragment>;

export type LinkFieldMultipleFragmentTypes = PossibleTypes<LinkFieldMultipleFragment>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseLinkField = (data: MaybeArrayOf<any>): Link | null => {
  const link = firstNonNullable(data);

  if (!link || !link.url) return null;

  const { target, type, text, title, url: href } = link;

  const download = type === 'asset';
  const defaultTarget =
    type && ['asset', 'mail', 'url', 'tel'].includes(type) ? '_blank' : undefined;

  const field: Link = {
    href: toString(href),
    target: toStringOrNull(target) ?? defaultTarget,
    children: toStringOrNull(text) ?? undefined,
    title: toStringOrNull(title) ?? undefined,
    download,
  };

  return deleteUndefined(field);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseLinkFieldMultiple = (maybeElements: MaybeArrayOf<any>): Link[] | null => {
  return makeNonNullableArray(maybeElements).reduce((results, item) => {
    const asset = parseLinkField(item);
    if (asset) results.push(asset);
    return results;
  }, [] as Link[]);
};

export const parseLinkFieldFragment = createFragmentArrayParser(
  LINKFIELD_FRAGMENT,
  (data): Link | null => parseLinkField(data)
);

export const parseLinkFieldMultipleFragment = createFragmentArrayParser(
  LINKFIELD_MULTIPLE_FRAGMENT,
  (data): Link[] | null => parseLinkFieldMultiple(data)
);

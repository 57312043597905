import useShortlistStore from '@/lib/store/shortlist';
import IconButton, { IconButtonProps } from '../IconButton';

export type CardHeartProps = Partial<IconButtonProps>;

export const CardHeart = (props: CardHeartProps) => {
  const events = useShortlistStore((s) => s.events);
  const isShortlisted = events?.some((e) => e.id === props.itemID);

  return (
    <IconButton
      icon={isShortlisted ? 'heart' : 'heartOutlined'}
      variant="text"
      color="white"
      {...props}
    />
  );
};

import { createPropSanitiser, SanitiserTypes } from '@liquorice/ts-sanitiser';
import {
  firstNonNullable,
  makeNonNullableArray,
  toId,
  toNumberOrNull,
  toString,
} from '@liquorice/utils';
import {
  FieldOptionFragment,
  FormieAddressSubfieldFragment,
  FormieFieldFragment,
  FormieFormFragment,
  FormieNameSubfieldFragment,
  RowType,
} from '__generated__/graphql';
import { UseFormReturn } from 'react-hook-form';
import parseFormieCaptchas from './parseFormieCaptchas';
import { toBoolean, Typename } from '@liquorice/allsorts-craftcms-nextjs';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { PossibleTypes } from '@liquorice/types';
import parseAllowedKinds from '@/components/Formie/parseAllowedKinds';
import { FormValues } from '@/components/Formie/formTypes';

type RawForm = PossibleTypes<FormieFormFragment>;

type RawFieldElements =
  | PossibleTypes<FormieFieldFragment>
  | PossibleTypes<FormieAddressSubfieldFragment>
  | PossibleTypes<FormieNameSubfieldFragment>;

// type RawFormElements =

/**
 * __typename of top level Elements
 */
export type FieldTypename = Typename<RawFieldElements>;

// ----------------------------------------------------------------------------------------------------
// ---- Label Options ----

export type LabelPosition = 'default' | 'hidden';

export const parseLabelPosition = (data: Maybe<string>): LabelPosition => {
  if (!data) return 'default';

  const type = toString(data).split('\\').reverse()[0].toLowerCase();

  if (type === 'hidden') return type;

  return 'default';
};
// ----------------------------------------------------------------------------------------------------
// ---- Field Options ----

export type FieldOption = {
  value: string;
  label: string;
  isDefault?: boolean;
};

export const parseFieldOptions = (data: MaybeArrayOf<FieldOptionFragment>): FieldOption[] => {
  return makeNonNullableArray(data).map(({ value, label, isDefault }) => ({
    value: toString(value),
    label: toString(label),
    isDefault: !!isDefault,
  }));
};

const parseFormieRows = (data: MaybeArrayOf<RowType>) => {
  return makeNonNullableArray(data).map((row) => ({
    id: toId(row.id),
    rowFields: parseFields((row.rowFields ?? []) as RawFieldElements[]),
  }));
};

// ----------------------------------------------------------------------------------------------------
// --- Define the callbacks ---

export const sanitiseFormieField = createPropSanitiser((): RawFieldElements | null => null, {
  // FIXME: Temporary hack
  id: toId,
  errorMessage: toString,
  instructions: toString,
  handle: toString,
  label: toString,
  placeholder: toString,
  required: toBoolean,
  options: parseFieldOptions,
  multi: toBoolean,
  // conditions: parseFormieFieldCondition,
  enableConditions: toBoolean,
  labelPosition: parseLabelPosition,
  //Agree
  defaultState: toBoolean,
  checkedValue: toString,
  uncheckedValue: toString,
  descriptionHtml: cleanHtml,
  limitFiles: toNumberOrNull,
  sizeLimit: toNumberOrNull,
  sizeMinLimit: toNumberOrNull,
  allowedKinds: parseAllowedKinds,
  // //
  // //
  fields: 'sanitise',
  nestedRows: 'sanitise',
  rowFields: 'sanitise',
});

export type FormMethods = UseFormReturn<FormValues>;

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

type SanitiserReturnMap = SanitiserTypes<typeof sanitiseFormieField, 'ReturnMap'>;

export type SanitisedField<T extends FieldTypename = FieldTypename> = SanitiserReturnMap[T];
export type Field<T extends FieldTypename = FieldTypename> = SanitisedField<T> & {
  _fieldMeta?: FieldMeta;
  handle: string;
};

export type FieldMeta = {
  index: number;
  first: boolean;
  firstOfType: boolean;
  nthOfType: number;
  last: boolean;
  previousField?: Field;
};

// ----------------------------------------------------------------------------------------------------

export type FieldComponentProps<T extends FieldTypename, P = NoProps> = {
  // FIXME: Temporary hack
  field: Field<T> | any;
  enabled?: boolean;
  methods: FormMethods;
} & P;

export const sanitiseFields = (maybeFields: MaybeArrayOf<RawFieldElements>) => {
  return sanitiseFormieField.many(maybeFields) as SanitisedField[];
};

export const parseSanitisedFields = (sanitisedFields: SanitisedField[]): Field[] => {
  const nthOfTypeCount: Record<string, number> = {};

  return sanitisedFields.map((v, i): Field => {
    const type = v.__typename;

    nthOfTypeCount[type] = type in nthOfTypeCount ? nthOfTypeCount[type] + 1 : 0;
    return {
      ...v,
      _fieldMeta: {
        nthOfType: nthOfTypeCount[type],
        index: i,
        first: i === 0,
        firstOfType: !!sanitisedFields.find((x, j) => j < i && x.__typename === v.__typename),
        last: i === sanitisedFields.length - 1,
        previousField: sanitisedFields[i - 1] as Field,
      },
    } as Field;
    /* return deleteUndefined({
      ...v,
      _fieldMeta: {
        nthOfType: nthOfTypeCount[type],
        index: i,
        first: i === 0,
        firstOfType: !!sanitisedFields.find((x, j) => j < i && x.__typename === v.__typename),
        last: i === sanitisedFields.length - 1,
        previousField: sanitisedFields[i - 1],
      },
    }); */
  });
};

export const parseFields = (maybeFields: MaybeArrayOf<RawFieldElements>): Field[] => {
  return parseSanitisedFields(sanitiseFields(maybeFields));
};

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFieldType = <T extends FieldTypename>(x: any, typename: T): x is Field<T> => {
  return !!x && x.__typename === typename;
};

// ----------------------------------------------------------------------------------------------------
// ---- Form ----

export const sanitiseFormieForm = createPropSanitiser((): RawForm | null => null, {
  id: toId,
  handle: toString,
  submissionMutationName: toString,
  captchas: parseFormieCaptchas,
});

export const parseFormieForm = (data: MaybeArrayOf<RawForm>) => {
  // FIXME: Temporary hack
  const form = firstNonNullable(data) as any;

  if (!form) return null;

  const { rows, submissionMutationName, ...rest } = form;

  return {
    ...rest,
    rows: parseFormieRows(rows),
    captchas: parseFormieCaptchas(form.captchas),
    // formFields: parseFields(formFields),
    uid: toId(form.uid),
    submissionMutationName: toString(submissionMutationName),
  };
};

export type ParsedForm = NonNullable<ReturnType<typeof parseFormieForm>>;

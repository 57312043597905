import { ImageFragmentTypes, parseImage } from '@/lib/parsers/images';
import { parseUri } from '@liquorice/utils';
import Card from '../Card';
import Box from '../ui/Box';
import Divider from '../ui/Divider';
import { EntryCardProps } from './EntryCardProps';

export type ArticleCardProps = EntryCardProps<'article'>;

export const ArticleCard = ({ item, ExtraProps, ...props }: ArticleCardProps) => {
  const { uri, title, entryTitle, entrySummary: description, postDate, entryImage } = item;
  const { customText } = ExtraProps ?? {};
  const href = parseUri(uri);
  const image = parseImage(entryImage as MaybeArrayOf<ImageFragmentTypes>);

  return (
    <Card
      disableLinkWrap
      colorSet="white"
      paper
      fullHeight
      elevation={false}
      {...props}
      item={{
        href,
        image,
        title: entryTitle ?? title,
        meta: postDate,
        description,
        customText,
      }}>
      <Box cx={{ mB: '2xs' }}>
        <Card.Image ratio="landscape" enableLinkWrap />
      </Box>
      <Card.Body cx={{ mB: 'lg' }}>
        <Card.Title variant="medium" weight="bold" />
        <Card.Description variant="small" color="neutralDark" enableTruncate />
      </Card.Body>
      <Card.Foot cx={{ display: 'flex', justifyContent: 'between' }}>
        <Card.Meta cx={{ fontFamily: 'mono', fontSize: 'tiny' }} uppercase />
        {href && (
          <Card.Cta
            variant="text"
            weight="bold"
            href={href}
            EndIconProps={{ name: 'chevronRight', cx: { fontSize: 'h5' } }}
            cx={{ fontSize: 'medium', mB: 'xs' }}
          />
        )}
      </Card.Foot>
      <Divider direction="horizontal" cx={{ color: 'primary' }} />
    </Card>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import elementSanitiser, { SanitisedElement } from '@/lib/sanitise/elementSanitiser';
import { Typename } from '@liquorice/allsorts-craftcms-nextjs';
import { PossibleTypes } from '@liquorice/types';
import { maybeGet, slugify } from '@liquorice/utils';
import { BlocksFragment } from '__generated__/graphql';

export type BlockFragments = PossibleTypes<BlocksFragment>;

/**
 * __typename of top level Block
 */
export type BlockTypename = Typename<BlockFragments>;

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

export type SanitisedBlock<T extends BlockTypename = BlockTypename> = SanitisedElement<T>;
export type Block<T extends BlockTypename = BlockTypename> = SanitisedElement<T> & {
  _blockMeta?: BlockMeta;
};

export type BlockMeta = {
  typename: BlockTypename;
  level?: number;
  inView?: boolean;
  index: number;
  first: boolean;
  firstOfType: boolean;
  nthOfType: number;
  last: boolean;
  previousBlock?: Block;
  inTimeline?: boolean;
  firstInTimeline?: boolean;
  lastInTimeline?: boolean;
};

// ----------------------------------------------------------------------------------------------------

/**
 * Create a {@link Block} consumer React Component by providing
 * the `__typename` of `BlockType` as T
 */
export const createBlock = <T extends BlockTypename, P = NoProps, R = JSX.Element | null>(
  // FIXME: Temporary hack
  fn: (
    props: any

    // Partial<Block<T>> & P
  ) => R
) => fn;

export const sanitiseBlocks = (maybeBlocks: MaybeArrayOf<BlockFragments>) => {
  return elementSanitiser.many(maybeBlocks) as SanitisedBlock[];
};

export const parseSanitisedBlocks = (sanitisedBlocks: SanitisedBlock[] = []): Block[] => {
  const nthOfTypeCount: Record<string, number> = {};

  return sanitisedBlocks
    .map((v, i): Block | null => {
      const type = v.__typename;
      // const nextBlock: Block | undefined = sanitisedBlocks[i + 1];
      nthOfTypeCount[type] = type in nthOfTypeCount ? nthOfTypeCount[type] + 1 : 0;

      return {
        ...v,
        _blockMeta: {
          typename: type,
          nthOfType: nthOfTypeCount[type],
          index: i,
          first: i === 0,
          firstOfType: !!sanitisedBlocks.find((x, j) => j < i && x.__typename === v.__typename),
          last: i === sanitisedBlocks.length - 1,
          // previousBlock: sanitisedBlocks[i - 1],
        },
      };
    })
    .filter(Boolean) as Block[];
};

export const parseBlocks = (maybeBlocks: MaybeArrayOf<BlockFragments>): Block[] => {
  return parseSanitisedBlocks(sanitiseBlocks(maybeBlocks));
};

const sanitiseBlockFragment = (data: MaybeArrayOf<BlockFragments>): Block[] => {
  const parsed = elementSanitiser.many(data);

  return parsed as unknown as Block[];
};

export type BlockView<T extends BlockTypename = BlockTypename> = {
  anchorLabel?: string;
  anchor?: string;
  block: Block<T>;
  _blockMeta?: BlockMeta;
};

export const filterBlocks = (blocks: Block[]) => {
  // Count instances of identical anchors
  const anchorCount: Record<string, number> = {};

  const anchors: [anchor: string, label: string][] = [];

  const filteredBlocks = blocks.map((block, blockKey) => {
    const heading = maybeGet(block, 'heading');
    const anchorRaw = slugify(heading ?? `block-${blockKey}`);

    anchorCount[anchorRaw] = anchorCount[anchorRaw] ? anchorCount[anchorRaw] + 1 : 1;

    const anchor =
      anchorCount[anchorRaw] > 1 ? `${anchorRaw}-${anchorCount[anchorRaw]}` : anchorRaw;

    if (heading) anchors.push([anchor, heading]);

    return {
      anchorLabel: heading,
      anchor,
      block,
    } as BlockView;
  });

  return {
    anchors,
    blocks: filteredBlocks,
  };
};

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBlock = <T extends BlockTypename>(x: any, typename: T): x is Block<T> => {
  return !!x && x.__typename === typename;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBlockView = <T extends BlockTypename>(x: any, typename: T): x is BlockView<T> => {
  return !!x && 'block' in x && x.block.__typename === typename;
};

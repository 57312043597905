import { isEntry } from '@/lib/parsers/entries';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { ImageFragmentTypes, parseImage } from '@/lib/parsers/images';
import { parseUri } from '@liquorice/utils';
import Card from '../Card';
import Box from '../ui/Box';
import { ArticleCard } from './EntryCard.Article';
import { EventCard } from './EntryCard.Event';
import { OverviewCard } from './EntryCard.Overview';
import { EntryCardProps } from './EntryCardProps';

export const EntryCardDefault = ({ ExtraProps, ...props }: EntryCardProps) => {
  if (!props.item) return null;

  if (isEntry(props.item, 'article'))
    return <ArticleCard {...(props as EntryCardProps<'article'>)} />;
  if (isEntry(props.item, 'event')) return <EventCard {...(props as EntryCardProps<'event'>)} />;
  if (isEntry(props.item, 'overview'))
    return <OverviewCard {...(props as EntryCardProps<'overview'>)} />;

  const { uri, title, entryTitle, entrySummary: description, entryImage } = props.item as PageEntry;
  const href = parseUri(uri);
  const image = parseImage(entryImage as MaybeArrayOf<ImageFragmentTypes>);

  return (
    <Card
      {...props}
      paper={false}
      fullHeight
      elevation={false}
      padding="md"
      item={{
        title: entryTitle ?? title,
        description,
        image,
        href,
        customText: ExtraProps?.customText,
      }}>
      <Box>
        <Card.Image ratio="landscape" />
      </Box>
      <Card.Body>
        <Card.Title variant="h4" color="primary" cx={{ mB: 'none', fontFamily: 'mono' }} />
        <Card.Description color="primary" />
      </Card.Body>
      <Card.Foot>
        <Card.Cta variant="outlined" size="normal" fullWidth cx={{ fontSize: 'medium' }} />
      </Card.Foot>
    </Card>
  );
};

export default EntryCardDefault;

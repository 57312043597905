import { ImageFragmentTypes, parseImage } from '@/lib/parsers/images';
import { useViewColorScheme } from '@/lib/store/hooks';
import { parseUri } from '@liquorice/utils';
import Card from '../Card';
import Box from '../ui/Box';
import { EntryCardProps } from './EntryCardProps';

export type OverviewCardProps = EntryCardProps<'overview'>;

export const OverviewCard = ({ ExtraProps, item, ...props }: OverviewCardProps) => {
  const { colorScheme: colorSet, oppositeScheme } = useViewColorScheme(item?.colorScheme);

  if (!item) return null;

  const { uri, title, entryTitle, entrySummary: description, entryImage } = item;
  const { customText } = ExtraProps ?? {};
  const href = parseUri(uri);
  const image = parseImage(entryImage as MaybeArrayOf<ImageFragmentTypes>);

  return (
    <Card
      {...props}
      colorSet={colorSet}
      paper
      fullHeight
      elevation={false}
      padding="lg"
      item={{
        title: entryTitle ?? title,
        description,
        image,
        href,
        customText,
      }}>
      <Box cx={{ pX: 'sm', pT: 'sm' }}>
        <Card.Image ratio="landscape" />
      </Box>
      <Card.Body>
        <Card.Title variant="h4" cx={{ mB: 'none' }} />
        <Card.Description />
      </Card.Body>
      <Card.Foot>
        <Card.Cta variant="outlined" color={oppositeScheme} rounded="small" />
      </Card.Foot>
    </Card>
  );
};

export default OverviewCard;

import { EVENT_INFO_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { toStringOrNull } from '@liquorice/utils';
import { EventInfoCardFragment } from '__generated__/graphql';

type EventInfo = {
  heading?: string | null;
  content?: string;
};

export const parseEventInfo = (data: Maybe<EventInfoCardFragment>): EventInfo | null => {
  if (!data) return null;

  const { heading, content } = data ?? {};

  return {
    heading: toStringOrNull(heading),
    content: cleanHtml(content),
  };
};

export const parseEventInfoFragment = createFragmentArrayParser(
  EVENT_INFO_CARD_FRAGMENT,
  (items) => {
    return items.map((item) => parseEventInfo(item));
  }
);

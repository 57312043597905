export type Status = 'live' | 'expired';

export const parseStatus = (status?: string | null): Status | null => {
  if (!status) return null;

  switch (status) {
    case 'live':
      return 'live';
    case 'expired':
      return 'expired';
    default:
      return null;
  }
};

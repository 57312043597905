import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63V3WrDIBTA8fs%2BheDNCu3QGKO1t9uDJBqT9csta7sv9u7DBsZJKYcDG979Y3%2BcKiRuSOnIvmaMLZdR7zdpJxzjohLKhjWo8mYtHOOqVsZ7WJVj3BqrY4S1dIz7ypfTqh3jUcervZVjvA1CWAmryXuNb4OB1eYabawVrKtLbUU72VvnGqKKGtbmsjeuYg2rd4w%2FSl2oB1hDPgehpZ5M1jrGjc4L1ugYr31esHaO8dLmBWs%2FzgDTUz6EQggh1rPv2f0Yd5O72jh2roe736ubw99vpw%2FNHDB7jJEY00PmgDEKYyRkEsYU5GmeMaYkT%2FOCMZrMDBhTkf%2FUK8YY8jRHjFmRmRPGWDJz%2Fh%2FmDWMaMvOOMZ58Ux8YE8jMJ8a0ZEYKzImY000ciTkdfZ4Cc3ryZUmFOU%2F0eco%2FztOkMN64T7s0XB3LIn8lxtdxU%2FttN6TTISxv7OwXl%2Fd%2BFn8Ae5bcZ4sHAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62WO5ObMBSFe%2F8KlbuzSKO3hLbaIpMqXfoMxrKtBIMDZF%2BZ%2FPfM2gaMuBTeob2Ij3PP4TC4uqpa9HeFEMY%2FWDB8HRh16Ot3%2FHTwdcgz%2FOW19eXGb5KrYYKe6pAVCWqyssGNr8P2cYRg7pbT%2FKbTYiTvW1VWN0mTDuVFdjjeccK4qv0hQXlW5HeYEqM5s%2BZjhh6QJpSp55f7BMnLwfsxSHUgRnQEUpJK0YEkUYydQAIG6QF0zaHEUHsmowfECOP2ROkeF1HMQDFXFEaETVlHocQofqLwKcHOEXhqe1soSSU9EwgFhaQDhkX7aAkoYeRsVYTJ5lyx4D6MAIx1xxDkCsEJVbqPRxBK7SVnAJHPp5xSJnolinAtLimD%2B2xcv%2Bn4gl9g0e2s56mUSg8UYfSFAkW36zB09AZQonQKUSix0Kr7OQz8KrIpIfQlHTlurQELOgX8HAwZh6a1Aqs5Rfya9VRZAfYSsKJYINzDMuGWQyo2SpfBvQS%2FVtUsRqewminjCLuCGeFGg52aMn73jJEGAdsKbVIvsEmzTGPaAaPHEUs44svjIswfhySl49mzQyqevTik49mrQyaevQH3vjsUTT5%2BGOjj6t9qXW3eTv8S26pscRPe%2FacdLkLp8d6H3b79dIMK37a%2Bxs0xy0O5O2v8D%2BXR%2FurwCAAA%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tXzFhY2ViNHEwOiAxMjsKICAtLV8xYWNlYjRxMTogMTZweDsKICAtLV8xYWNlYjRxMjogMjRweDsKICAtLV8xYWNlYjRxMzogMjRweDsKfQ%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fspacing.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0ta2RtdnFpMDogMDsKICAtLWtkbXZxaTE6IDFweDsKICAtLWtkbXZxaTI6IDJweDsKICAtLWtkbXZxaTM6IHZhcigtLV8xYWNlYjRxMyk7CiAgLS1rZG12cWk0OiB2YXIoLS1fMWFjZWI0cTEpOwogIC0ta2RtdnFpNTogNDBweDsKICAtLWtkbXZxaTY6IDAuMTI1cmVtOwogIC0ta2RtdnFpNzogMC4yNXJlbTsKICAtLWtkbXZxaTg6IDAuMzc1cmVtOwogIC0ta2RtdnFpOTogMC41cmVtOwogIC0ta2RtdnFpYTogMC43NXJlbTsKICAtLWtkbXZxaWI6IDFyZW07CiAgLS1rZG12cWljOiAxLjI1cmVtOwogIC0ta2RtdnFpZDogMS41cmVtOwogIC0ta2RtdnFpZTogMnJlbTsKICAtLWtkbXZxaWY6IDIuMzc1cmVtOwogIC0ta2RtdnFpZzogY2xhbXAoMi4zNzVyZW0sIGNhbGMoMS40NzI3NXJlbSArIDEuODgwdncpLCAzcmVtKTsKICAtLWtkbXZxaWg6IGNsYW1wKDNyZW0sIGNhbGMoMi4yNzgxODc1cmVtICsgMS41MDR2dyksIDMuNXJlbSk7CiAgLS1rZG12cWlpOiBjbGFtcCgzLjVyZW0sIGNhbGMoMi4wNTYzNzVyZW0gKyAzLjAwOHZ3KSwgNC41cmVtKTsKICAtLWtkbXZxaWo6IGNsYW1wKDMuNXJlbSwgY2FsYygxLjMzNDU2MjVyZW0gKyA0LjUxMXZ3KSwgNXJlbSk7CiAgLS1rZG12cWlrOiBjbGFtcCg1cmVtLCBjYWxjKDEuMzkxcmVtICsgNy41MTl2dyksIDcuNXJlbSk7Cn0%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42U227bMAyG7%2FcUvGyBKBDlU8K9QN%2Bgl4N8aKImsVzZSdwVffdBWtKZctsMsGH608%2BftCyJnLUDvP0AEMK6sdvlkkCglPLnhCGBWHOkCJCThCDjJCXIOclCFkM5ASYcFQTsfUWQFXk3TtmaoMhXnGnvrlIOy7%2F%2BHFYEmKZRek2gOGkIers39ZQ9Rc1tCOQyc81hCrcerooYGwJcoorxM4GaWewITtrdCfELTaFKg%2F39dHhPgFHGwRedmbe%2BZjJrxc7zu7jiC6v4Ms9wvuLMuo999sxn8F87n4Sj73M%2BZScC2Y3hzrsR3KbUd3IBl2uJCfM%2B%2F1OjvC0fJ%2FL%2FcH%2Bljz7UNS9OUdn94sM0%2FUKEGfP9HX5cz3acpKDjED8RKs8SzhLPUs7SsE45ywhKXe02zh7bWlR2b90CLo%2FSurpxVxh6gUb3jTCtsMeB%2BeSf%2BXyfUhDYTldmeL0hXBEMTrf9k3WHG9I1wbYxm%2B2wgIMeH66haa%2Fh2dTDNgw%2BXiLTXqLvnXW06bEkiM4ZrAiiAwVrCuuKsYYgCafR%2Bx%2B8b%2FjTfAUAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fui%2FTxt%2FTxt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82Y3W7iMBCF73mKuVmpKyUowfwGaV9lZRIndusf6jgFuuq7rwpLY7PIQ1VAvSWf5xxiz%2FgoQ7dzvM7G8GcAUBppbAEv1D6k6eFBloDQnFnhfi4HALXRLt0w0XAXciOfexsMD79O9mU%2Fah2X1BP1aGTmk9MYmfvkLEaOfHIeI4lPLmLk2CdpjJz45CpGTn2yjJEzn6xi5NwnWYxc%2BGQdI6lPNjFy5ZM8RpY%2BKWJk5ZOPMZL55FOMrH1SxsjGJ1WM5D6pY6TwSbMnz7TU71zMRiuR7%2Fqeq6kScncKZD3Qild2%2BljsH0uhWcrPCgRHe31rO4%2BYnaB%2Fnm9t5wmzEzSpvbUdidkJJkF7azsKsxOMG3drOxqzE8y0rrdzXi2PqzlMLZgNL19UazG1YGZtvqhmMbVglm6%2FqPaMqQUzfoepIadkjakFt9Qrdmg77NAif95gdoLrNc8xP%2Fklfvp6o6s0ZV%2BPYPW2WL0gdOWH0KmobYROV8Y5owrIhlOmfOqQIR3butRZqtvaWFVAt14zW9KW%2Bej0kg3t8RmGvwT4HMM3Ab645G194AS4UzKBlanQJsjDha2zRjefVKtF01kGv0Cow9KNqBwvIM%2ByH%2B%2BbeDyvtHNm6e2SNOVTynRVQB5sE4HVJx10MgFzCGBrWlVCN6lktSsgH85LHqBSXHJSCHC0g%2B6bqAjw6%2FTg1UIVAY528X1zFQE%2B%2Fl7RigCffK90RYCjo%2B2%2BAYuAE%2FqiQXW1lEWgVVTKu2YtAopVolN3jVwEJLUNu2vwIrBF3%2BxVsxc5uem%2BQwB7v0AS4KMEOEmAjxPgk%2BTYeujlQ%2F%2F%2FhNZ%2Fk%2FoXYCpWGkudMLqATlfMvltbDt7%2BAvkEnE%2BGEwAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'tythf04',variantClassNames:{color:{primaryAlt:'tythf05',primary:'tythf06',primaryTintDark:'tythf07',primaryTint:'tythf08',primaryTintLight:'tythf09',primaryTintLighter:'tythf0a',secondaryAlt:'tythf0b',secondary:'tythf0c',secondaryTintDark:'tythf0d',secondaryTint:'tythf0e',secondaryTintLight:'tythf0f',secondaryTintLighter:'tythf0g',notifyRed:'tythf0h',notifyGreen:'tythf0i',neutralDark:'tythf0j',neutralLight:'tythf0k',black:'tythf0l',white:'tythf0m',error:'tythf0n'},variant:{h1:'tythf0o',h2:'tythf0p',h3:'tythf0q',h4:'tythf0r',h5:'tythf0s',h6:'tythf0t',tiny:'tythf0u',small:'tythf0v',medium:'tythf0w',large:'tythf0x',xl:'tythf0y',body:'tythf0z',inherit:'tythf010'},bodyFont:{true:'tythf011'},headingFont:{true:'tythf012'},monoFont:{true:'tythf013'},autoMargin:{true:'tythf014'},uppercase:{true:'tythf015'},weight:{regular:'tythf016',medium:'tythf017',semibold:'tythf018',bold:'tythf019'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['color','variant','bodyFont','headingFont','monoFont','autoMargin','uppercase','weight']};
export var txtVars = {color:'var(--tythf00)',display:'var(--tythf01)',fontWeight:'var(--tythf02)'};
export var typographyClasses = {h1:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1i)',lineHeight:'var(--_1i72bi14)'},h2:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1j)',lineHeight:'var(--_1i72bi15)'},h3:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1k)',lineHeight:'var(--_1i72bi16)'},h4:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1l)',lineHeight:'var(--_1i72bi17)'},h5:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1m)',lineHeight:'var(--_1i72bi18)'},h6:{fontWeight:'var(--_1i72bi1y)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1n)',lineHeight:'var(--_1i72bi19)'},tiny:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1t)',lineHeight:'var(--_1i72bi1g)'},small:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1s)',lineHeight:'var(--_1i72bi1f)'},medium:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1r)',lineHeight:'var(--_1i72bi1e)'},large:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1q)',lineHeight:'var(--_1i72bi1d)'},xl:{fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1p)',lineHeight:'var(--_1i72bi1b)'},body:{fontWeight:'var(--_1i72bi1u)',fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1o)',lineHeight:'var(--_1i72bi1a)'},inherit:{}};
export var withHtml = 'tythf03';
// store.ts
import { create } from 'zustand';
import { Entry } from '../parsers/entries';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface ShortlistState extends ShortlistActions {
  events: Entry[] | null;
  latestEvent: Entry | null;
  notification: boolean;
}

interface ShortlistActions {
  setEvents: (events: Entry[] | null) => void;
  addEvent: (event: Entry) => void;
  removeEvent: (eventId: string) => void;
  clearEvents: () => void;
  toggleEvent: (event: Entry) => void;
  setNotification: (notification: boolean) => void;
}

export const useShortlistStore = create<ShortlistState>()(
  persist(
    (set) => ({
      events: [],
      latestEvent: null,
      notification: false,
      setEvents: (events: Entry[] | null) => set({ events }),
      addEvent: (newEvent: Entry) => {
        set((state) => {
          const updatedEvents = [...(state.events || []), newEvent];
          return {
            events: updatedEvents,
            latestEvent: newEvent,
            notification: true,
          };
        });
      },
      removeEvent: (eventId: string) => {
        set((state) => {
          const updatedEvents = state.events?.filter((e) => e.id !== eventId) ?? null;
          return {
            events: updatedEvents,
            latestEvent: null,
            notification: false,
          };
        });
      },
      clearEvents: () => {
        set({
          events: null,
          latestEvent: null,
          notification: false,
        });
      },
      toggleEvent: (event: Entry) => {
        set((state) => {
          const isEventPresent = state.events?.some((e) => e.id === event.id);
          const updatedEvents = isEventPresent
            ? state.events?.filter((e) => e.id !== event.id) ?? null
            : [...(state.events || []), event];
          return {
            events: updatedEvents,
            latestEvent: !isEventPresent
              ? event
              : updatedEvents
              ? updatedEvents[updatedEvents.length - 1]
              : null,
            notification: !isEventPresent,
          };
        });
      },
      setNotification: (notification: boolean) => set({ notification }),
    }),
    {
      name: 'shortlist-storage', // name of item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default the 'localStorage' is used
      partialize: (state) => ({ events: state.events, latestEvent: state.latestEvent }),
      // onRehydrateStorage: (state) => {
      //   console.log('hydration starts');

      //   // optional
      //   return (state, error) => {
      //     if (error) {
      //       console.log('an error happened during hydration', error);
      //     } else {
      //       console.log('hydration finished');
      //     }
      //   };
      // },
    }
  )
);

export const useShortlistCount = (state: ShortlistState) => state.events?.length ?? 0;

export const useShortlistLength = (state: ShortlistState) => !!state.events?.length;

export default useShortlistStore;

import { DOCUMENT_FRAGMENT } from '@/gql/fragments/document.gql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { PossibleTypes } from '@liquorice/types';
import {
  deleteUndefined,
  firstNonNullable,
  humanFileSize,
  makeNonNullableArray,
  toStringOrNull,
} from '@liquorice/utils';
import { DocumentFragment } from '__generated__/graphql';

export type DocumentFragmentTypes = PossibleTypes<DocumentFragment>;

export type DocumentAsset = NonNullable<ReturnType<typeof parseDocument>>;

export const parseDocument = (maybeElement?: MaybeArrayOf<Partial<DocumentFragment>>) => {
  const doc = firstNonNullable(maybeElement);
  if (!doc) return null;
  const { size, ...rest } = doc;

  // const summary = toStringOrNull(maybeGet(doc, 'summary')) ?? undefined;
  const title = toStringOrNull(doc.title) ?? undefined;
  const id = toStringOrNull(doc.id) ?? undefined;

  return deleteUndefined({
    ...rest,
    id,
    title,
    /* summary, */
    size,
    sizeHuman: humanFileSize(size),
  });
};

export const parseDocumentMany = (maybeElements?: MaybeArrayOf<DocumentFragment>) => {
  return makeNonNullableArray(maybeElements).reduce((results, item) => {
    const asset = parseDocument(item);
    if (asset) results.push(asset);
    return results;
  }, [] as DocumentAsset[]);
};

export const parseDocumentFragment = createFragmentArrayParser(DOCUMENT_FRAGMENT, (data) => {
  return parseDocument(data);
});

export const parseDocumentManyFragment = createFragmentArrayParser(DOCUMENT_FRAGMENT, (data) => {
  return parseDocumentMany(data);
});

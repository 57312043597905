import { gql } from '__generated__';

export const COMMON_LINK_FRAGMENT = gql(`
  fragment commonLink on EntryInterface {
    id
    uri
    title
  }
`);

export const COMMON_LINK_QUERY = gql(`
    query commonLinks($site: [String] = "default") {
    home: entry(site: $site, uri: "__home__") {
      ...commonLink
    }
    search: entry(site: $site, section: "search") {
      ...commonLink
    }
    articleIndex: entry(site: $site, section: "articleIndex") {
      ...commonLink
    }
    eventIndex: entry(site: $site, section: "page", type: "index") {
      ...commonLink
    }
  }
`);

import { Color, colorSetVariants } from '@/theme/partials/palette.css';
import { assertUnreachable } from '@liquorice/utils';
import { ColorScheme } from '__generated__/graphql';

export const parseColorScheme = (data: Maybe<ColorScheme>): Color | undefined => {
  const label = data?.label;

  return label && label in colorSetVariants ? (label as Color) : undefined;
};

/**
 * A switch statement that returns the opposite color scheme of the given color.
 * This is primarily used for text color.
 */
export const findOppositeColorScheme = (color: Color): Color => {
  switch (color) {
    case 'primaryAlt':
      return 'secondary';
    case 'primary':
      return 'white';
    case 'primaryTintDark':
      return 'primary';
    case 'primaryTint':
      return 'primary';
    case 'primaryTintLight':
      return 'primary';
    case 'primaryTintLighter':
      return 'primary';
    case 'secondaryAlt':
      return 'white';
    case 'secondary':
      return 'primary';
    case 'secondaryTintDark':
      return 'primary';
    case 'secondaryTint':
      return 'primary';
    case 'secondaryTintLight':
      return 'primary';
    case 'secondaryTintLighter':
      return 'primary';
    case 'notifyRed':
      return 'white';
    case 'notifyGreen':
      return 'white';
    case 'neutralDark':
      return 'white';
    case 'neutralLight':
      return 'white';
    case 'black':
      return 'white';
    case 'white':
      return 'primary';
    case 'error':
      return 'white';
    default:
      return assertUnreachable(color);
  }
};

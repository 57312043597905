import Image, { ImageProps } from '@/components/Image';
import { fmtFullName } from '@/lib/utils/format';
import { mergePropsClassName } from '@liquorice/utils';
import NextAnchor from '../ui/NextAnchor';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardImageProps = ImageProps & {
  enableLinkWrap?: boolean;
};

export const CardImage = ({ ShimProps = {}, enableLinkWrap, ...props }: CardImageProps) => {
  const { honorific, firstName, lastName, image, href, disabled } = useCard();

  const title = fmtFullName(honorific, firstName, lastName);

  const inner = image && (
    <Image
      alt={title ?? ''}
      {...mergePropsClassName(
        {
          ratio: 'landscapeWide',
          ...image,
          ...props,
        },
        style.image
      )}
      ShimProps={mergePropsClassName(ShimProps, style.imageWrap({ disabled }))}
    />
  );

  if (enableLinkWrap && href) {
    return <NextAnchor href={href}>{inner}</NextAnchor>;
  }

  return inner;
};

'use client';

import { createContext } from 'react';
import { CommonLinks } from '../getters/getCommonLinks';
import { Entry } from '../parsers/entries';

export type AppContext = {
  view?: Entry | null;
  commonLinks?: CommonLinks;
};

const initialState: AppContext = {};

export const appContext = createContext<AppContext>(initialState);

export const { Provider: AppContextProvider } = appContext;

export default AppContextProvider;

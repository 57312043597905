import * as style from './IconButton.css';

import Icon, { IconName, IconProps } from '@/components/Icon';
import Btn, { BtnComponentWithRef, BtnProps } from '@/components/ui/Btn';
import { Polymorphic } from '@/components/ui/Polymorphic';

import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';

export type IconButtonBaseProps = {
  icon: IconName;
  IconProps?: Omit<IconProps, 'name'>;
};

export type IconButtonProps<C extends React.ElementType = 'button'> = BtnProps<
  C,
  IconButtonBaseProps
>;

const IconButton: BtnComponentWithRef<IconButtonBaseProps> = React.forwardRef(function IconButton<
  C extends React.ElementType
>({ icon, IconProps, _children, ...props }: IconButtonProps<C>, ref?: Polymorphic.Ref<C>) {
  return (
    <Btn ref={ref} square variant="icon" size="tiny" {...mergePropsClassName(props, style.root)}>
      <Icon name={icon} {...mergePropsClassName(IconProps, style.icon)} />
    </Btn>
  );
});

export default IconButton;

import { LOCALE, TIMEZONE } from '@/lib/constants';
import { firstNonNullable, makeNonNullableArray, toId, toNumber, toString } from '@liquorice/utils';
import { Category } from '../parsers/categories';
import { DisplayRoleMode } from '../parsers/common';
import { EventTime } from '../parsers/common/parseEventTime';

export const fmtRole = (
  role: string,
  displayRoleMode: DisplayRoleMode,
  displayRole?: string | null
) => {
  if (displayRoleMode === 'none') return role;
  if (displayRoleMode === 'appendToRole') return `${role}, ${displayRole}`;
  if (displayRoleMode === 'overwriteRole') return displayRole;
};

export const fmtCategoryNames = (
  maybeCats?: MaybeArrayOf<Category>,
  join = ', ',
  exclude?: MaybeArrayOf<Category>,
  disableJoin?: boolean
) => {
  const excludeIds = makeNonNullableArray(exclude).map((v) => v.id);
  const cats = makeNonNullableArray(maybeCats).filter((v) => !excludeIds.includes(v.id));

  if (disableJoin) return cats.map((v) => v.title);

  return cats.map((v) => v.title).join(join);
};

export const fmtCategoryAddress = (maybeCat?: MaybeArrayOf<Category>) => {
  const cat = firstNonNullable(maybeCat) as Category<'venueCategory'>;
  if (!cat) return undefined;

  const address = firstNonNullable(cat?.address);
  if (!address) return undefined;

  const {
    addressLine1,
    addressLine2,
    addressLine3,
    administrativeArea,
    countryCode,
    // organization,
    // organizationTaxId,
    postalCode,
    locality,
  } = address ?? {};

  const addressParts: string[] = [];

  if (addressLine1) addressParts.push(addressLine1);
  if (addressLine2) addressParts.push(addressLine2);
  if (addressLine3) addressParts.push(addressLine3);
  if (locality) addressParts.push(locality);
  if (administrativeArea) addressParts.push(administrativeArea);
  if (postalCode) addressParts.push(postalCode);
  if (countryCode) addressParts.push(countryCode);

  return addressParts.filter(Boolean).join(', ');
};

export const fmtCategoryIds = (maybeCats?: MaybeArrayOf<Category>) => {
  const cats = makeNonNullableArray(maybeCats);
  const ids = cats.reduce((acc: string[], category) => {
    acc.push(toId(category.id));
    return acc;
  }, []);

  if (!ids.length) return undefined;

  return ids;
};

export const fmtDate = (n?: string | number | null | Date) => {
  if (!n) return undefined;

  let date = n;

  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(n);
  }

  // Format hours and minutes and convert to 12-hour format
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${date.getDate()} ${date.toLocaleString(LOCALE, {
    month: 'short',
    timeZone: TIMEZONE,
  })}, ${date.getFullYear()}, ${hours}:${minutes} ${ampm}`;
};

export const fmtSimpleDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const toIsoString = (date: Date): string => {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (n: number) {
      return (n < 10 ? '0' : '') + n;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
};

export const fmtEventTime = (maybeEventTimes?: (EventTime | null)[]): null | string | undefined => {
  if (!maybeEventTimes) return null;
  const dates: string[] = maybeEventTimes?.map((item) => toString(item?.date)) ?? [];
  const earliestDate = fmtEarliestDate(dates);
  const latestDate = fmtLatestDate(dates);
  return fmtDateRange(earliestDate, latestDate);
};

export const fmtEarliestDate = (dates: string[]) => {
  if (!dates.length) return null;

  const res: Date[] = dates.map((date) => new Date(date));

  const earliestDate: Date = new Date(Math.min(...res.map((date) => date.getTime())));

  return earliestDate;
};

export const fmtLatestDate = (dates: string[]) => {
  if (!dates.length) return null;

  const res: Date[] = dates.map((date) => new Date(date));

  const latestDate: Date = new Date(Math.max(...res.map((date) => date.getTime())));

  return latestDate;
};

export const fmtDateRange = (
  start?: string | number | null | Date,
  end?: string | number | null | Date,
  join = '-'
) => {
  if (!start) return fmtDate(end);
  if (!end) return fmtDate(start);

  let startDate = start;
  let endDate = end;

  if (typeof startDate === 'string' || typeof startDate === 'number') {
    startDate = new Date(start);
  }

  if (typeof endDate === 'string' || typeof endDate === 'number') {
    endDate = new Date(end);
  }

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const startMonth = startDate.toLocaleString(LOCALE, {
    month: 'short',
    timeZone: TIMEZONE,
  });
  const endMonth = endDate.toLocaleString(LOCALE, {
    month: 'short',
    timeZone: TIMEZONE,
  });
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();

  const endStr = `${endDay} ${endMonth}, ${endYear}`;

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      if (startDay === endDay) return endStr;

      return `${startDay}${join}${endStr}`;
    }
    return `${startDay} ${startMonth} ${join}${endStr}`;
  }
  return `${startDay} ${startMonth}, ${startYear}${join}${endStr}`;
};

export const fmtTime = (value?: string | number | null, options?: Intl.DateTimeFormatOptions) => {
  if (!value) return null;

  const time = new Date(value);

  const formattedTime = time
    .toLocaleString(LOCALE, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: TIMEZONE,
      ...options,
    })
    .replace(' ', '');

  return formattedTime;
};

export const fmtTimeRange = (
  start?: string | number | null,
  end?: string | number | null,
  join = '-',
  options?: Intl.DateTimeFormatOptions
) => {
  if (!start) return fmtTime(end, options);
  if (!end) return fmtTime(start, options);

  return `${fmtTime(start, { ...options, timeZoneName: undefined })}${join}${fmtTime(
    end,
    options
  )}`;
};

export const fmtPercent = (n?: string | number | null) => {
  return `${toNumber(n)}%`;
};

export const fmtMoney = (n?: string | number | null) => {
  return `$${toNumber(n)}`;
};

export const floatPoint = (n: string | number | null) => {
  return toNumber(parseFloat(`${toNumber(n)}`).toPrecision(12));
};

export const roundToTwo = (n: string | number | null) => {
  return Math.round(toNumber(n) * 100) / 100;
};

export const numberedLabel = (n: number | undefined, name: string, nameSingular: string) =>
  (Math.abs(n ?? 0) === 1 ? nameSingular : name).replace('%n', `${n ?? 0}`);

export const removeOrphan = (str: string, charLim = 10) => {
  const lastIndex = str.lastIndexOf(' ');

  if (lastIndex < 1) return str;

  const before = str.slice(0, lastIndex);
  const after = str.slice(lastIndex + 1);

  if (after.length > charLim) return str;

  return (
    <>
      {before}&nbsp;{after}
    </>
  );
};

export const fmtSplitOnCapital = (str: string) => {
  const split = str.split(/(?=[A-Z])/);

  return split.join(' ');
};

export const fmtProperNoun = (str: string): string => {
  return str.replace(/^\w/, (c) => c.toUpperCase());
};

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const fmtTitleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const fmtFullName = (
  honorific?: string | null,
  firstName?: string | null,
  lastName?: string | null
) => {
  if (!firstName || !lastName) return null;

  return `${honorific ?? ''} ${firstName ?? ''} ${lastName ?? ''}`;
};

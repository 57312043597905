'use client';

import { Category } from '@/lib/parsers/categories';
import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { fmtDate } from '@/lib/utils/format';
import { useBreakpointMax } from '@/theme';
import { Dialog, DialogTitle } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import IconButton from '../IconButton';
import { PageHeaderProps } from '../PageHeader';
import Box, { BoxProps } from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import Flex from '../ui/Flex';

type PageTicketProps = BoxProps<
  'div',
  {
    TicketBtnProps?: BtnProps;
    parentEntry?: Entry<'event'>;
  }
> &
  Partial<PageHeaderProps>;

export default function PageTicket({
  children,
  parentEntry,
  TicketBtnProps,
  ...props
}: PageTicketProps) {
  const [open, setOpen] = useState(false);
  const t = useTranslations();
  const page = useView();
  const sm = useBreakpointMax('sm');

  const event = page as Entry<'event'>;
  const { eventTime, status, venueCategory, eventId } = parentEntry ?? event ?? {};

  // FIX ME
  const venue = venueCategory as unknown as Category<'venueCategory'>;
  const expired = status === 'expired';
  const disabled = expired || !eventTime?.length;

  const content = expired
    ? t('entryIndex.thisIsAnArchived') + ' ' + t('entryIndex.event')
    : t('actions.buyTickets');

  return (
    <>
      <Box {...props}>
        <Btn
          color={expired ? 'error' : 'primary'}
          disabled={disabled}
          size="large"
          weight="medium"
          startIcon={!expired ? 'ticket' : undefined}
          fullWidth
          onClick={() => setOpen(true)}
          {...TicketBtnProps}>
          {content}
        </Btn>
      </Box>
      <Dialog
        fullScreen={sm}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        PaperProps={{ sx: { width: '100%' } }}
        open={open}>
        <Flex direction="row" justifyContent="between">
          <DialogTitle>Select Perfomance</DialogTitle>
          <Box cx={{ pX: 'md', pY: 'xs' }}>
            <IconButton icon="close" onClick={() => setOpen(false)} />
          </Box>
        </Flex>
        <Flex rowGap="xs" cx={{ pX: 'md', pB: 'xs' }}>
          {children ||
            eventTime?.map((event, index) => {
              return (
                event && (
                  <Btn
                    key={index}
                    variant="outlined"
                    color="primary"
                    size="large"
                    target="_blank"
                    href={`https://tickets.geelongartscentre.org.au/WebPages/EntaWebGateway/gateway.aspx?E=N&QL=S${eventId}|V${venue?.venueShorthand}|P${event?.eventTimeId}|G~/WEBPAGES/EntaWebHtmlSeatPlan/HtmlSeatPlan.aspx`}>
                    {fmtDate(event?.date)}
                  </Btn>
                )
              );
            })}
        </Flex>
      </Dialog>
    </>
  );
}

/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment blockBase on NeoBlockInterface {\n    id\n    __typename\n    level\n  }\n": types.BlockBaseFragmentDoc,
    "\n  fragment richTextBlock on blocks_richText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n": types.RichTextBlockFragmentDoc,
    "\n  fragment accordionItemBlock on blocks_accordionItem_BlockType {\n    heading\n    content: htmlContentSimple\n  }\n": types.AccordionItemBlockFragmentDoc,
    "\n  fragment accordionBlock on blocks_accordion_BlockType {\n    children {\n      ...accordionItemBlock\n    }\n  }\n": types.AccordionBlockFragmentDoc,
    "\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkField {\n      ...linkField\n    }\n    flipAlignment\n    imageSingle {\n      ...image\n    }\n    lightswitch\n  }\n": types.ImageAndTextBlockFragmentDoc,
    "\n  fragment logoAndTextBlock on blocks_logoAndText_BlockType {\n    heading\n    imageMultiple {\n      ...image\n    }\n    lightswitch\n  }\n": types.LogoAndTextBlockFragmentDoc,
    "\n  fragment imageBlock on blocks_image_BlockType {\n    imageSingle {\n      ...image\n    }\n    caption\n  }\n": types.ImageBlockFragmentDoc,
    "\n  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {\n    children {\n      ...imageBlock\n    }\n  }\n": types.ImageCarouselBlockFragmentDoc,
    "\n  fragment videoBlock on blocks_video_BlockType {\n    videoUrl\n  }\n": types.VideoBlockFragmentDoc,
    "\n  fragment quoteBlock on blocks_quote_BlockType {\n    heading \n    label\n  }\n": types.QuoteBlockFragmentDoc,
    "\n  fragment quoteCarouselBlock on blocks_quoteCarousel_BlockType {\n    children {\n      ...quoteBlock\n    }\n  }\n": types.QuoteCarouselBlockFragmentDoc,
    "\n  fragment fileDownloadBlock on blocks_fileDownload_BlockType {\n    heading\n    fileMultiple {\n      ...document\n    }\n  }\n": types.FileDownloadBlockFragmentDoc,
    "\n  fragment dividerBlock on blocks_divider_BlockType {\n    __typename\n  }\n": types.DividerBlockFragmentDoc,
    "\n  fragment formBlock on blocks_form_BlockType {\n    heading\n    content: htmlContentSimple\n    form {\n      ...formieForm\n    }\n  }\n": types.FormBlockFragmentDoc,
    "\n  fragment eventCreditBlock on blocks_eventCredit_BlockType {\n    heading\n    label\n  }\n": types.EventCreditBlockFragmentDoc,
    "\n  fragment eventCreditsBlock on blocks_eventCredits_BlockType {   \n    children {\n      ...eventCreditBlock\n    }\n  }\n": types.EventCreditsBlockFragmentDoc,
    "\n  fragment categoryTypeBlock on blocks_categoryType_BlockType {\n    heading\n  }\n": types.CategoryTypeBlockFragmentDoc,
    "\n  fragment eventIndex on blocks_eventIndex_BlockType {\n    heading\n\n    # Filters\n    latestEvents\n    dateRange {\n      end\n      isFuture\n      isNotPast\n      isOnGoing\n      isPast\n      start\n    }\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    genreCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n  }\n": types.EventIndexFragmentDoc,
    "\n  fragment eventSearch on blocks_eventSearch_BlockType {\n    heading\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n": types.EventSearchFragmentDoc,
    "\n  fragment featuredEvent on blocks_featuredEvent_BlockType {\n    eventSingle {\n      ...eventCard\n    }\n  }\n": types.FeaturedEventFragmentDoc,
    "\n  fragment simpleCardBlock on blocks_simpleCard_BlockType {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n": types.SimpleCardBlockFragmentDoc,
    "\n  fragment expandingCardBlock on blocks_expandingCard_BlockType {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    flipAlignment\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n": types.ExpandingCardBlockFragmentDoc,
    "\n  fragment externalCardBlock on blocks_externalCard_BlockType {  \n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": types.ExternalCardBlockFragmentDoc,
    "\n  fragment overviewCardBlock on blocks_overviewCard_BlockType {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n": types.OverviewCardBlockFragmentDoc,
    "\n  fragment entryCardsBlock on blocks_entryCards_BlockType {\n    heading\n    children {\n      ...simpleCardBlock\n      ...externalCardBlock\n    }\n  }\n": types.EntryCardsBlockFragmentDoc,
    "\n  fragment expandingCardsBlock on blocks_expandingCards_BlockType {\n    children {\n      ...expandingCardBlock\n      ...externalCardBlock\n    }\n  }\n": types.ExpandingCardsBlockFragmentDoc,
    "\n  fragment overviewCardsBlock on blocks_overviewCards_BlockType {\n    children {\n      ...overviewCardBlock\n      ...externalCardBlock\n    }\n  }\n": types.OverviewCardsBlockFragmentDoc,
    "\n  fragment blocks on blocks_NeoField {\n    ...blockBase\n    ...richTextBlock\n    ...accordionBlock\n    ...quoteBlock\n    ...quoteCarouselBlock\n    ...dividerBlock\n\n    # Event fragments\n    ...categoryTypeBlock\n    ...eventIndex\n    ...eventSearch\n    ...featuredEvent\n    ...eventCreditsBlock\n\n    # Media fragments\n    ...imageAndTextBlock\n    ...logoAndTextBlock\n    ...imageBlock\n    ...imageCarouselBlock\n    ...videoBlock\n    ...fileDownloadBlock\n    ...formBlock\n\n    # Card fragments\n    ...simpleCardBlock\n    ...expandingCardBlock\n    ...externalCardBlock\n    ...overviewCardBlock\n\n    ...entryCardsBlock\n    ...expandingCardsBlock\n    ...overviewCardsBlock\n  }\n": types.BlocksFragmentDoc,
    "\n  fragment categoryId on CategoryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n    }\n  }\n": types.CategoryIdFragmentDoc,
    "\n  fragment accessibilityCategory on accessibilityCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": types.AccessibilityCategoryFragmentDoc,
    "\n  fragment eventTypeCategory on eventTypeCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n": types.EventTypeCategoryFragmentDoc,
    "\n  fragment genreCategory on genreCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    imageSingle {\n      ...image\n    }\n  }\n": types.GenreCategoryFragmentDoc,
    "\n  fragment venueCategory on venueCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    label\n    address {\n      ...address\n    }\n    venueSingle {\n      ...venueCard\n    }\n    venueShorthand\n  }\n": types.VenueCategoryFragmentDoc,
    "\n  fragment programCategory on programCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    colorScheme {\n      label\n    }\n  }\n": types.ProgramCategoryFragmentDoc,
    "\n  fragment categories on CategoryInterface {\n    ...accessibilityCategory\n    ...eventTypeCategory\n    ...genreCategory\n    ...venueCategory\n    ...programCategory\n  }\n  ": types.CategoriesFragmentDoc,
    "\n  fragment socialLinks on socialLinks_Entry {\n    __typename\n    facebook\n    twitter\n    instagram\n  }\n": types.SocialLinksFragmentDoc,
    "\n  fragment address on Address {\n    __typename\n    addressLine1\n    addressLine2\n    addressLine3\n    administrativeArea\n    countryCode\n    organization\n    organizationTaxId\n    postalCode\n    sortingCode\n    latitude\n    locality\n    longitude\n    language\n    dependentLocality\n  }\n": types.AddressFragmentDoc,
    "\n  fragment document on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n": types.DocumentFragmentDoc,
    "\n  fragment pageBranch on EntryInterface {\n    __typename\n    title\n    uri\n    id\n    children {\n      title\n      uri\n      id\n      children {\n        title\n        uri\n        id\n      }\n    }\n  }\n": types.PageBranchFragmentDoc,
    "\n  fragment entryId on EntryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n      title\n    }\n  }\n": types.EntryIdFragmentDoc,
    "\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    title\n    sectionHandle\n    status\n    enabled\n    postDate @formatDateTime(format: \"j M, Y\")\n    parent {\n      id\n      uri\n      title\n    }\n  }\n": types.EntryBaseFragmentDoc,
    "\n  fragment articleIndexEntry on index_Entry {\n    ...articleIndexCard\n    articleSingle {\n      ...articleCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n": types.ArticleIndexEntryFragmentDoc,
    "\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n    eventMultiple {\n      ...eventCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n": types.ArticleEntryFragmentDoc,
    "\n  fragment homeEntry on home_Entry {\n    ...homeCard\n    blocks {\n      ...blocks\n    }\n\n    # Hero section\n    eventSingle {\n      ...eventCard\n    }\n  }\n": types.HomeEntryFragmentDoc,
    "\n  fragment venueEntry on venue_Entry {\n    ...venueCard\n  }\n": types.VenueEntryFragmentDoc,
    "\n  fragment pageStandardEntry on standard_Entry {\n    ...pageStandardCard\n    pagesBranch: children {\n      ...pageBranch\n    }\n    ancestors {\n      id\n      title\n      uri\n      parent {\n        id\n      }\n    }\n    blocks {\n      ...blocks\n    }\n  }\n": types.PageStandardEntryFragmentDoc,
    "\n  fragment pageOverviewEntry on overview_Entry {\n    ...pageOverviewCard\n    blocks {\n      ...blocks\n    }\n  }\n": types.PageOverviewEntryFragmentDoc,
    "\n  fragment pageExpandableEntry on expandable_Entry {\n    ...pageExpandableCard\n    blocks {\n      ...blocks\n    }\n  }\n": types.PageExpandableEntryFragmentDoc,
    "\n  fragment shortlistEntry on index_Entry {\n    ...shortlistCard\n  }\n": types.ShortlistEntryFragmentDoc,
    "\n  fragment searchEntry on index_Entry {\n    ...searchCard\n  }\n": types.SearchEntryFragmentDoc,
    "\n  fragment notFoundEntry on notFound_Entry {\n    ...notFoundCard\n  }\n": types.NotFoundEntryFragmentDoc,
    "\n  fragment eventIndexEntry on index_Entry {\n    ...eventIndexCard\n  }\n": types.EventIndexEntryFragmentDoc,
    "\n  fragment eventEntry on event_Entry {\n    ...eventCard\n    blocks {\n      ...blocks\n    }\n    onSale\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    eventPrice {\n      ...eventPriceCard\n    }\n    eventInfo {\n      ...eventInfoCard\n    }\n    faqMultiple {\n      ...faqCard\n    }\n  }\n": types.EventEntryFragmentDoc,
    "\n  fragment eventInfoEntry on eventInfo_Entry {\n    ...eventInfoCard\n  }\n": types.EventInfoEntryFragmentDoc,
    "\n  fragment eventPriceEntry on eventPrice_Entry {\n    ...eventPriceCard\n  }\n": types.EventPriceEntryFragmentDoc,
    "\n  fragment eventTimeEntry on eventTime_Entry {\n    ...eventTimeCard\n  }\n": types.EventTimeEntryFragmentDoc,
    "\n  fragment entries on EntryInterface {\n    ...entryBase\n    ...entrySeo\n    ...articleIndexEntry\n    ...articleEntry\n    ...homeEntry\n    ...venueEntry\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...pageExpandableEntry\n    ...shortlistEntry\n    ...searchEntry\n    ...notFoundEntry\n\n    ...eventIndexEntry\n    ...eventEntry\n    \n    # ...faqEntry\n\n    # Event matrix entries\n    # ...eventInfoEntry\n    # ...eventPriceEntry\n    # ...eventTimeEntry\n  }\n": types.EntriesFragmentDoc,
    "\n  fragment articleIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.ArticleIndexCardFragmentDoc,
    "\n  fragment articleCard on article_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.ArticleCardFragmentDoc,
    "\n  fragment homeCard on home_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.HomeCardFragmentDoc,
    "\n  fragment venueCard on venue_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.VenueCardFragmentDoc,
    "\n  fragment pageStandardCard on standard_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": types.PageStandardCardFragmentDoc,
    "\n  fragment pageOverviewCard on overview_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": types.PageOverviewCardFragmentDoc,
    "\n  fragment pageExpandableCard on expandable_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n": types.PageExpandableCardFragmentDoc,
    "\n  fragment shortlistCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.ShortlistCardFragmentDoc,
    "\n  fragment searchCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.SearchCardFragmentDoc,
    "\n  fragment notFoundCard on notFound_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.NotFoundCardFragmentDoc,
    "\n  fragment faqCard on faq_Entry {\n    heading\n    content: htmlContentDefault\n  }\n": types.FaqCardFragmentDoc,
    "\n  fragment eventIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n": types.EventIndexCardFragmentDoc,
    "\n  fragment eventCard on event_Entry {\n    ...entryBase\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    videoUrl\n    genreCategory {\n      ...categories\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n  }\n": types.EventCardFragmentDoc,
    "\n  fragment eventPriceCard on eventPrice_Entry {\n    ...entryBase\n    ownerId\n    title\n    minPrice\n    maxPrice\n  }\n": types.EventPriceCardFragmentDoc,
    "\n  fragment eventTimeCard on eventTime_Entry {\n    ...entryBase\n    ownerId\n    date\n    eventTimeId\n  }\n": types.EventTimeCardFragmentDoc,
    "\n  fragment eventInfoCard on eventInfo_Entry {\n    ...entryBase\n    heading\n    content: htmlContentSimple\n  }\n": types.EventInfoCardFragmentDoc,
    "\n  fragment entryCards on EntryInterface {\n    ...articleIndexCard\n    ...articleCard\n    ...homeCard  \n    ...venueCard\n    ...pageStandardCard\n    ...pageOverviewCard\n    ...pageExpandableCard\n    ...shortlistCard\n    ...searchCard\n    ...notFoundCard\n    ...eventIndexCard\n    ...eventCard\n    # ...eventPriceCard\n    # ...eventTimeCard\n    # ...eventInfoCard\n  }\n": types.EntryCardsFragmentDoc,
    "\n  fragment formieField on FieldInterface {\n    ...fieldAddress\n    ...fieldAgree\n    ...fieldCheckboxes\n    ...fieldDropdown\n    ...fieldName\n    ...fieldRadio\n    ...fieldSingleLineText\n    ...fieldMultiLineText\n    ...fieldEmail\n    ...fieldNumber\n    ...fieldPhone\n    ...fieldFileUpload\n    ...fieldDate\n    ...formieAddressSubfield\n    ...formieNameSubfield\n    ...formieDateSubfield\n    #\n    ...fieldSection\n    ...fieldHeading\n}\n": types.FormieFieldFragmentDoc,
    "\n  fragment formieCaptcha on FormieCaptchaType {\n    __typename\n    handle\n    name\n    value\n  }\n": types.FormieCaptchaFragmentDoc,
    "\n  fragment formieForm on FormInterface {\n    __typename\n    id\n    uid\n    handle\n    submissionMutationName\n    slug\n    rows {\n      __typename\n      id\n      rowFields {\n        ...formieField\n      }\n    }\n    settings {\n      submitActionMessageHtml\n      errorMessageHtml\n    }\n    rows {\n      id\n      rowFields {\n        id\n      }\n    }\n    captchas {\n      ...formieCaptcha\n    }\n  }\n": types.FormieFormFragmentDoc,
    "\n  fragment fieldAddress1 on Field_Address1 {\n    ...fieldBase\n  }\n": types.FieldAddress1FragmentDoc,
    "\n  fragment fieldAddress2 on Field_Address2 {\n    ...fieldBase\n  }\n": types.FieldAddress2FragmentDoc,
    "\n  fragment fieldAddress3 on Field_Address3 {\n    ...fieldBase\n  }\n": types.FieldAddress3FragmentDoc,
    "\n  fragment fieldAddressCity on Field_AddressCity {\n    ...fieldBase\n  }\n": types.FieldAddressCityFragmentDoc,
    "\n  fragment fieldAddressCountry on Field_AddressCountry {\n    ...fieldBase\n    options {\n      ...fieldOption\n    }\n  }\n": types.FieldAddressCountryFragmentDoc,
    "\n  fragment fieldAddressState on Field_AddressState {\n    ...fieldBase\n  }\n": types.FieldAddressStateFragmentDoc,
    "\n  fragment fieldAddressZip on Field_AddressZip {\n    ...fieldBase\n  }\n": types.FieldAddressZipFragmentDoc,
    "\n  fragment fieldNameFirst on Field_NameFirst {\n    ...fieldBase\n  }\n": types.FieldNameFirstFragmentDoc,
    "\n  fragment fieldNameMiddle on Field_NameMiddle {\n    ...fieldBase\n  }\n": types.FieldNameMiddleFragmentDoc,
    "\n  fragment fieldNameLast on Field_NameLast {\n    ...fieldBase\n  }\n": types.FieldNameLastFragmentDoc,
    "\n  fragment fieldNamePrefix on Field_NamePrefix {\n    ...fieldBase\n    options {\n      ...fieldOption\n    }\n  }\n": types.FieldNamePrefixFragmentDoc,
    "\n  fragment formieAddressSubfield on FieldInterface {\n    ...fieldAddress1\n    ...fieldAddress2\n    ...fieldAddress3\n    ...fieldAddressCity\n    ...fieldAddressCountry\n    ...fieldAddressState\n    ...fieldAddressZip\n  }\n": types.FormieAddressSubfieldFragmentDoc,
    "\n  fragment formieNameSubfield on FieldInterface {\n    ...fieldNameFirst\n    ...fieldNameMiddle\n    ...fieldNameLast\n    ...fieldNamePrefix\n  }\n": types.FormieNameSubfieldFragmentDoc,
    "\n  fragment fieldAddress on Field_Address {\n    ...fieldBase\n    nestedRows {\n      __typename\n      id\n      rowFields {\n        ...formieAddressSubfield\n      }\n    }\n  }\n": types.FieldAddressFragmentDoc,
    "\n  fragment fieldAgree on Field_Agree {\n    ...fieldBase\n    uncheckedValue\n    checkedValue\n    inputTypeName\n    descriptionHtml\n    prePopulate\n    defaultState\n  }\n": types.FieldAgreeFragmentDoc,
    "\n  fragment fieldCheckboxes on Field_Checkboxes {\n    ...fieldBase\n    multi\n    layout\n    options {\n      ...fieldOption\n    }\n  }\n": types.FieldCheckboxesFragmentDoc,
    "\n  fragment fieldDropdown on Field_Dropdown {\n    ...fieldBase\n    multi\n    options {\n      ...fieldOption\n    }\n  }\n": types.FieldDropdownFragmentDoc,
    "\n  fragment fieldName on Field_Name {\n    ...fieldBase\n    useMultipleFields\n    nestedRows {\n      __typename\n      id\n      rowFields {\n        ...formieNameSubfield\n      }\n    }\n  }\n": types.FieldNameFragmentDoc,
    "\n  fragment fieldRadio on Field_Radio {\n    ...fieldBase\n    layout\n    options {\n      ...fieldOption\n    }\n  }\n": types.FieldRadioFragmentDoc,
    "\nfragment fieldSingleLineText on Field_SingleLineText {\n    ...fieldBase\n  }\n": types.FieldSingleLineTextFragmentDoc,
    "\n  fragment fieldMultiLineText on Field_MultiLineText {\n    ...fieldBase\n  }\n": types.FieldMultiLineTextFragmentDoc,
    "\n  fragment fieldEmail on Field_Email {\n    ...fieldBase\n  }\n": types.FieldEmailFragmentDoc,
    "\n  fragment fieldNumber on Field_Number {\n    ...fieldBase\n  }\n": types.FieldNumberFragmentDoc,
    "\n  fragment fieldPhone on Field_Phone {\n    ...fieldBase\n  }\n": types.FieldPhoneFragmentDoc,
    "\n  fragment fieldFileUpload on Field_FileUpload {\n    ...fieldBase\n    allowedKinds\n    limitFiles\n    sizeLimit\n    sizeMinLimit\n  }\n": types.FieldFileUploadFragmentDoc,
    "\n  fragment formieDateSubfield on FieldInterface {\n    # ...fieldDateYearDropdown\n    # ...fieldDateAmPmNumber\n    # ...fieldDateSecondNumber\n    # ...fieldDateMinuteNumber\n    # ...fieldDateHourNumber\n    # ...fieldDateDayNumber\n    # ...fieldDateMonthNumber\n    # ...fieldDateYearNumber\n    # ...fieldDateAmPmDropdown\n    # ...fieldDateSecondDropdown\n    # ...fieldDateMinuteDropdown\n    # ...fieldDateHourDropdown\n    # ...fieldDateDayDropdown\n    # ...fieldDateMonthDropdown\n    ...fieldSingleLineText\n}\n": types.FormieDateSubfieldFragmentDoc,
    "\n  fragment fieldDate on Field_Date {\n    ...fieldBase\n    defaultDate\n    fields {\n      ...formieDateSubfield\n    }\n  }\n": types.FieldDateFragmentDoc,
    "\n  fragment fieldBase on FieldInterface {\n    __typename\n    id\n    conditions\n    containerAttributes { label, value}\n    cssClasses\n    defaultValue\n    displayName\n    emailValue\n    enableConditions\n    enabled\n    errorMessage\n    handle\n    inputAttributes { label, value }\n    inputTypeName\n    instructions\n    label\n    labelPosition\n    matchField\n    placeholder\n    prePopulate\n    required\n    type\n    typeName\n    uid\n    visibility\n  }": types.FieldBaseFragmentDoc,
    "\n  fragment fieldOption on FieldOption {\n    __typename\n    value\n    label\n    isDefault\n  }\n": types.FieldOptionFragmentDoc,
    "\n  fragment fieldAttribute on FieldAttribute {\n    __typename\n    value\n    label\n  }\n": types.FieldAttributeFragmentDoc,
    "\n  fragment fieldHeading on Field_Heading {\n    __typename\n    id\n    label\n    headingSize\n    conditions\n    enableConditions\n  }\n": types.FieldHeadingFragmentDoc,
    "\n  fragment fieldSection on Field_Section {\n    __typename\n    id\n    label\n    conditions\n    enableConditions\n  }\n": types.FieldSectionFragmentDoc,
    "\n  fragment globalBase on GlobalSetInterface {\n    __typename\n    id\n    handle\n  }\n  ": types.GlobalBaseFragmentDoc,
    "\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n  }\n": types.GlobalCopyrightFragmentDoc,
    "\n  fragment globalCompanyInformation on companyInformation_GlobalSet {\n    content: htmlContentDefault\n  }\n": types.GlobalCompanyInformationFragmentDoc,
    "\n  fragment globalShoppingBasket on shoppingBasket_GlobalSet {\n    externalUrl\n  }\n": types.GlobalShoppingBasketFragmentDoc,
    "\n  fragment globalProfile on profile_GlobalSet {\n    externalUrl\n  }\n": types.GlobalProfileFragmentDoc,
    "\n  fragment globalSocialLinks on socialLinks_GlobalSet {\n    socialLinks {\n      ...socialLinks\n    }\n  }\n": types.GlobalSocialLinksFragmentDoc,
    "\n  fragment globals on GlobalSetInterface {\n    ...globalBase\n    ...globalCopyright\n    ...globalCompanyInformation\n    ...globalSocialLinks\n    ...globalShoppingBasket\n    ...globalProfile\n  }\n": types.GlobalsFragmentDoc,
    "\n  fragment imageSize_original on AssetInterface {\n    url \n    height \n    width \n    original_url: url\n    original_height: width\n    original_width: height\n  }\n": types.ImageSize_OriginalFragmentDoc,
    "\n  fragment imageSize_fullFit on AssetInterface {\n    fullFit_url: url\n    fullFit_height: width\n    fullFit_width: height\n  }\n": types.ImageSize_FullFitFragmentDoc,
    "\n  fragment imageSize_all on AssetInterface {\n    ...imageSize_original\n    ...imageSize_fullFit\n  }\n": types.ImageSize_AllFragmentDoc,
    "\n  fragment image on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    ...imageSize_all\n  }\n": types.ImageFragmentDoc,
    "\n  fragment linkFieldUrl on linkField_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldUrlFragmentDoc,
    "\n  fragment linkFieldEntry on linkField_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldEntryFragmentDoc,
    "\n  fragment linkFieldCustom on linkField_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldCustomFragmentDoc,
    "\n  fragment linkFieldEmail on linkField_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldEmailFragmentDoc,
    "\n  fragment linkFieldPhone on linkField_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldPhoneFragmentDoc,
    "\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldCustom\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n": types.LinkFieldFragmentDoc,
    "\n  fragment linkFieldMultipleUrl on linkFieldMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultipleUrlFragmentDoc,
    "\n  fragment linkFieldMultipleEntry on linkFieldMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultipleEntryFragmentDoc,
    "\n  fragment linkFieldMultipleCustom on linkFieldMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultipleCustomFragmentDoc,
    "\n  fragment linkFieldMultipleEmail on linkFieldMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultipleEmailFragmentDoc,
    "\n  fragment linkFieldMultiplePhone on linkFieldMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n": types.LinkFieldMultiplePhoneFragmentDoc,
    "\n  fragment linkFieldMultiple on HyperLinkInterface {\n    ...linkFieldMultipleUrl\n    ...linkFieldMultipleEntry\n    ...linkFieldMultipleCustom\n    ...linkFieldMultipleEmail\n    ...linkFieldMultiplePhone\n  }\n": types.LinkFieldMultipleFragmentDoc,
    "\n  fragment navigationNode on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n      uri\n      language\n    }\n  }\n": types.NavigationNodeFragmentDoc,
    "\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n": types.SeoMetaLinksFragmentDoc,
    "\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n": types.SeoMetaTagsFragmentDoc,
    "\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n": types.SeoTitleFragmentDoc,
    "\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n": types.SeoScriptsFragmentDoc,
    "\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n": types.SeoJsonLdFragmentDoc,
    "\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n": types.SeoSiteVarsFragmentDoc,
    "\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      metaTitleContainer\n      metaJsonLdContainer\n      metaLinkContainer\n      metaTagContainer\n      metaScriptContainer\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      metaSiteVarsContainer\n    }\n  }\n": types.EntrySeoFragmentDoc,
    "\n  query categories {\n    accessibilityCategory: categories(group: \"accessibilityCategory\") {\n      ...categories\n    }\n    eventTypeCategory: categories(group: \"eventTypeCategory\") {\n      ...categories\n    }\n    genreCategory: categories(group: \"genreCategory\") {\n      ...categories\n    }\n    locationCategory: categories(group: \"locationCategory\") {\n      ...categories\n    }\n    programCategory: categories(group: \"programCategory\") {\n      ...categories\n    }\n  }\n": types.CategoriesDocument,
    "\n  query category($group: [String]) {\n    category: categories(group: $group) {\n      ...categories\n    }\n  }\n": types.CategoryDocument,
    "\n  fragment commonLink on EntryInterface {\n    id\n    uri\n    title\n  }\n": types.CommonLinkFragmentDoc,
    "\n    query commonLinks($site: [String] = \"default\") {\n    home: entry(site: $site, uri: \"__home__\") {\n      ...commonLink\n    }\n    search: entry(site: $site, section: \"search\") {\n      ...commonLink\n    }\n    articleIndex: entry(site: $site, section: \"articleIndex\") {\n      ...commonLink\n    }\n    eventIndex: entry(site: $site, section: \"page\", type: \"index\") {\n      ...commonLink\n    }\n  }\n": types.CommonLinksDocument,
    "\n  query listEntries(\n    $site: [String] = \"default\",\n    $limit: Int,\n    $section: [String],\n    $type: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n    $relatedToCategories: [CategoryRelationCriteriaInput] = null\n  ) {\n    entries(\n      site: $site,\n      relatedToEntries: $relatedToEntries\n      relatedToCategories: $relatedToCategories\n      limit: $limit\n      section: $section\n      orderBy: $orderBy,\n      type: $type\n    ) {\n      ...entryId\n    }\n  }\n": types.ListEntriesDocument,
    "\n  query listCategories(\n    $site: [String] = \"default\",\n    $group: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n  ) {\n    categories(\n      site: $site,\n      group: $group,\n      orderBy: $orderBy,\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...categoryId\n    }\n  }\n": types.ListCategoriesDocument,
    "\n  query searchEntries(\n      $site: [String] = \"default\",\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput],\n      $fixedOrder: Boolean\n      $id: [QueryArgument]\n    ) {\n    entries(\n      site: $site,\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      fixedOrder: $fixedOrder\n      id: $id\n    ) {\n      ...entryCards\n    }\n    entryCount(\n      site: $site,\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      id: $id\n    )\n  }\n": types.SearchEntriesDocument,
    "\n    query eventPriceEntries {\n        eventPriceFieldEntries {\n            ...eventPriceCard\n        } \n    }\n": types.EventPriceEntriesDocument,
    "\n    query eventTimeEntries($date: [QueryArgument]) {\n        eventTimeFieldEntries(date: $date, orderBy: \"date ASC\") {\n            ...eventTimeCard\n        } \n    }\n": types.EventTimeEntriesDocument,
    "\n    query eventIndexEntries(\n        $genreCategory: [QueryArgument],\n        $venueCategory: [QueryArgument],\n        $accessibilityCategory: [QueryArgument],\n        $programCategory: [QueryArgument],\n        $eventTypeCategory: [QueryArgument],\n        $id: [QueryArgument],\n        $orderBy: String\n      ) {\n        entries(genreCategory: $genreCategory, venueCategory: $venueCategory, accessibilityCategory: $accessibilityCategory, programCategory: $programCategory, eventTypeCategory: $eventTypeCategory, id: $id, orderBy: $orderBy) {\n            ...entries\n        }\n    }\n": types.EventIndexEntriesDocument,
    "\n  query formExists($uid: [String]!) {\n    formieForm(uid: $uid) {\n      id\n      handle\n      submissionMutationName\n    }\n  }\n": types.FormExistsDocument,
    "\n  query globals($handle: [String]) {\n    globalSets(handle: $handle) {\n      ...globals\n    }\n  }\n": types.GlobalsDocument,
    "\n  query navMenus {\n    primaryNav: navigationNodes(navHandle: \"primaryNav\") {\n      ...navigationNode\n    }\n    secondaryNav: navigationNodes(navHandle: \"secondaryNav\") {\n      ...navigationNode\n    }\n    footerNav: navigationNodes(navHandle: \"footerNav\") {\n      ...navigationNode\n    }\n    footerExtrasNav: navigationNodes(navHandle: \"footerExtrasNav\") {\n      ...navigationNode\n    }\n    auxiliaryNav: navigationNodes(navHandle: \"auxiliaryNav\") {\n      ...navigationNode\n    }\n    policiesNav: navigationNodes(navHandle: \"policiesNav\") {\n      ...navigationNode\n    }\n  }\n": types.NavMenusDocument,
    "\n  query navMenu($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      ...navigationNode\n    }\n  }\n": types.NavMenuDocument,
    "\n  query allUris(\n    $section: [String], \n    $type: [String], \n    $orderBy: String, \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entries(section: $section, type: $type, orderBy: $orderBy, status: $status) {\n      id\n      uri\n      slug\n    }\n  }\n": types.AllUrisDocument,
    "\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    $status: [String] = [\"live\"]\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy, status: $status) {\n      ...entries\n    }\n  }\n": types.ContentByUriDocument,
    "\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $descendantOf: Int\n    $descendantDist: Int\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, descendantOf: $descendantOf, descendantDist: $descendantDist, status: $status) {\n      ...entries\n    }\n  }\n": types.ContentByTypeDocument,
    "\n  query entryExists(\n    $uri: [String], \n    $slug: [String], \n    $id: [QueryArgument], \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entry(uri: $uri, id: $id, slug: $slug, status: $status, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n": types.EntryExistsDocument,
    "\n  query relatedEntries(\n    $site: [String] = \"default\"\n    $exclude: QueryArgument\n    $section: [String]\n    $categoryId: [QueryArgument]\n    $parentId: Int\n    $orderBy: String\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(\n      site: $site\n      limit: 4\n      id: [\"not\", $exclude]\n      relatedTo: $categoryId\n      section: $section\n      orderBy: $orderBy\n      descendantOf: $parentId\n      status: $status\n    ) {\n      ...entryCards\n    }\n  }\n": types.RelatedEntriesDocument,
    "\n  query allImportedEvents(\n    $orderBy: String, \n    $status: [String]\n    ) {\n    eventEntries(orderBy: $orderBy, status: $status) {\n      ... on event_Entry {\n        title\n        eventId\n      }\n    }\n  }\n": types.AllImportedEventsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment blockBase on NeoBlockInterface {\n    id\n    __typename\n    level\n  }\n"): (typeof documents)["\n  fragment blockBase on NeoBlockInterface {\n    id\n    __typename\n    level\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment richTextBlock on blocks_richText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"): (typeof documents)["\n  fragment richTextBlock on blocks_richText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accordionItemBlock on blocks_accordionItem_BlockType {\n    heading\n    content: htmlContentSimple\n  }\n"): (typeof documents)["\n  fragment accordionItemBlock on blocks_accordionItem_BlockType {\n    heading\n    content: htmlContentSimple\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accordionBlock on blocks_accordion_BlockType {\n    children {\n      ...accordionItemBlock\n    }\n  }\n"): (typeof documents)["\n  fragment accordionBlock on blocks_accordion_BlockType {\n    children {\n      ...accordionItemBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkField {\n      ...linkField\n    }\n    flipAlignment\n    imageSingle {\n      ...image\n    }\n    lightswitch\n  }\n"): (typeof documents)["\n  fragment imageAndTextBlock on blocks_imageAndText_BlockType {\n    heading\n    content: htmlContentDefault\n    linkField {\n      ...linkField\n    }\n    flipAlignment\n    imageSingle {\n      ...image\n    }\n    lightswitch\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment logoAndTextBlock on blocks_logoAndText_BlockType {\n    heading\n    imageMultiple {\n      ...image\n    }\n    lightswitch\n  }\n"): (typeof documents)["\n  fragment logoAndTextBlock on blocks_logoAndText_BlockType {\n    heading\n    imageMultiple {\n      ...image\n    }\n    lightswitch\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageBlock on blocks_image_BlockType {\n    imageSingle {\n      ...image\n    }\n    caption\n  }\n"): (typeof documents)["\n  fragment imageBlock on blocks_image_BlockType {\n    imageSingle {\n      ...image\n    }\n    caption\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {\n    children {\n      ...imageBlock\n    }\n  }\n"): (typeof documents)["\n  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {\n    children {\n      ...imageBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment videoBlock on blocks_video_BlockType {\n    videoUrl\n  }\n"): (typeof documents)["\n  fragment videoBlock on blocks_video_BlockType {\n    videoUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment quoteBlock on blocks_quote_BlockType {\n    heading \n    label\n  }\n"): (typeof documents)["\n  fragment quoteBlock on blocks_quote_BlockType {\n    heading \n    label\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment quoteCarouselBlock on blocks_quoteCarousel_BlockType {\n    children {\n      ...quoteBlock\n    }\n  }\n"): (typeof documents)["\n  fragment quoteCarouselBlock on blocks_quoteCarousel_BlockType {\n    children {\n      ...quoteBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fileDownloadBlock on blocks_fileDownload_BlockType {\n    heading\n    fileMultiple {\n      ...document\n    }\n  }\n"): (typeof documents)["\n  fragment fileDownloadBlock on blocks_fileDownload_BlockType {\n    heading\n    fileMultiple {\n      ...document\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment dividerBlock on blocks_divider_BlockType {\n    __typename\n  }\n"): (typeof documents)["\n  fragment dividerBlock on blocks_divider_BlockType {\n    __typename\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment formBlock on blocks_form_BlockType {\n    heading\n    content: htmlContentSimple\n    form {\n      ...formieForm\n    }\n  }\n"): (typeof documents)["\n  fragment formBlock on blocks_form_BlockType {\n    heading\n    content: htmlContentSimple\n    form {\n      ...formieForm\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventCreditBlock on blocks_eventCredit_BlockType {\n    heading\n    label\n  }\n"): (typeof documents)["\n  fragment eventCreditBlock on blocks_eventCredit_BlockType {\n    heading\n    label\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventCreditsBlock on blocks_eventCredits_BlockType {   \n    children {\n      ...eventCreditBlock\n    }\n  }\n"): (typeof documents)["\n  fragment eventCreditsBlock on blocks_eventCredits_BlockType {   \n    children {\n      ...eventCreditBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment categoryTypeBlock on blocks_categoryType_BlockType {\n    heading\n  }\n"): (typeof documents)["\n  fragment categoryTypeBlock on blocks_categoryType_BlockType {\n    heading\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventIndex on blocks_eventIndex_BlockType {\n    heading\n\n    # Filters\n    latestEvents\n    dateRange {\n      end\n      isFuture\n      isNotPast\n      isOnGoing\n      isPast\n      start\n    }\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    genreCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n  }\n"): (typeof documents)["\n  fragment eventIndex on blocks_eventIndex_BlockType {\n    heading\n\n    # Filters\n    latestEvents\n    dateRange {\n      end\n      isFuture\n      isNotPast\n      isOnGoing\n      isPast\n      start\n    }\n    venueCategory {\n      ...categories\n    }\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    genreCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventSearch on blocks_eventSearch_BlockType {\n    heading\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"): (typeof documents)["\n  fragment eventSearch on blocks_eventSearch_BlockType {\n    heading\n    linkFieldMultiple {\n      ...linkFieldMultiple\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment featuredEvent on blocks_featuredEvent_BlockType {\n    eventSingle {\n      ...eventCard\n    }\n  }\n"): (typeof documents)["\n  fragment featuredEvent on blocks_featuredEvent_BlockType {\n    eventSingle {\n      ...eventCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment simpleCardBlock on blocks_simpleCard_BlockType {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment simpleCardBlock on blocks_simpleCard_BlockType {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment expandingCardBlock on blocks_expandingCard_BlockType {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    flipAlignment\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment expandingCardBlock on blocks_expandingCard_BlockType {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    flipAlignment\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment externalCardBlock on blocks_externalCard_BlockType {  \n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment externalCardBlock on blocks_externalCard_BlockType {  \n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n    imageSingle {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment overviewCardBlock on blocks_overviewCard_BlockType {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"): (typeof documents)["\n  fragment overviewCardBlock on blocks_overviewCard_BlockType {\n    entrySingle {\n      ...entryCards\n    }\n    \n    # Override props\n    heading\n    content: htmlContentSimple\n    linkField {\n      ...linkField\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryCardsBlock on blocks_entryCards_BlockType {\n    heading\n    children {\n      ...simpleCardBlock\n      ...externalCardBlock\n    }\n  }\n"): (typeof documents)["\n  fragment entryCardsBlock on blocks_entryCards_BlockType {\n    heading\n    children {\n      ...simpleCardBlock\n      ...externalCardBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment expandingCardsBlock on blocks_expandingCards_BlockType {\n    children {\n      ...expandingCardBlock\n      ...externalCardBlock\n    }\n  }\n"): (typeof documents)["\n  fragment expandingCardsBlock on blocks_expandingCards_BlockType {\n    children {\n      ...expandingCardBlock\n      ...externalCardBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment overviewCardsBlock on blocks_overviewCards_BlockType {\n    children {\n      ...overviewCardBlock\n      ...externalCardBlock\n    }\n  }\n"): (typeof documents)["\n  fragment overviewCardsBlock on blocks_overviewCards_BlockType {\n    children {\n      ...overviewCardBlock\n      ...externalCardBlock\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment blocks on blocks_NeoField {\n    ...blockBase\n    ...richTextBlock\n    ...accordionBlock\n    ...quoteBlock\n    ...quoteCarouselBlock\n    ...dividerBlock\n\n    # Event fragments\n    ...categoryTypeBlock\n    ...eventIndex\n    ...eventSearch\n    ...featuredEvent\n    ...eventCreditsBlock\n\n    # Media fragments\n    ...imageAndTextBlock\n    ...logoAndTextBlock\n    ...imageBlock\n    ...imageCarouselBlock\n    ...videoBlock\n    ...fileDownloadBlock\n    ...formBlock\n\n    # Card fragments\n    ...simpleCardBlock\n    ...expandingCardBlock\n    ...externalCardBlock\n    ...overviewCardBlock\n\n    ...entryCardsBlock\n    ...expandingCardsBlock\n    ...overviewCardsBlock\n  }\n"): (typeof documents)["\n  fragment blocks on blocks_NeoField {\n    ...blockBase\n    ...richTextBlock\n    ...accordionBlock\n    ...quoteBlock\n    ...quoteCarouselBlock\n    ...dividerBlock\n\n    # Event fragments\n    ...categoryTypeBlock\n    ...eventIndex\n    ...eventSearch\n    ...featuredEvent\n    ...eventCreditsBlock\n\n    # Media fragments\n    ...imageAndTextBlock\n    ...logoAndTextBlock\n    ...imageBlock\n    ...imageCarouselBlock\n    ...videoBlock\n    ...fileDownloadBlock\n    ...formBlock\n\n    # Card fragments\n    ...simpleCardBlock\n    ...expandingCardBlock\n    ...externalCardBlock\n    ...overviewCardBlock\n\n    ...entryCardsBlock\n    ...expandingCardsBlock\n    ...overviewCardsBlock\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment categoryId on CategoryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment categoryId on CategoryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment accessibilityCategory on accessibilityCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"): (typeof documents)["\n  fragment accessibilityCategory on accessibilityCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventTypeCategory on eventTypeCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"): (typeof documents)["\n  fragment eventTypeCategory on eventTypeCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment genreCategory on genreCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    imageSingle {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment genreCategory on genreCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    imageSingle {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment venueCategory on venueCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    label\n    address {\n      ...address\n    }\n    venueSingle {\n      ...venueCard\n    }\n    venueShorthand\n  }\n"): (typeof documents)["\n  fragment venueCategory on venueCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    label\n    address {\n      ...address\n    }\n    venueSingle {\n      ...venueCard\n    }\n    venueShorthand\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment programCategory on programCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment programCategory on programCategory_Category {\n    __typename\n    id\n    uri\n    slug\n    groupHandle\n    title\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment categories on CategoryInterface {\n    ...accessibilityCategory\n    ...eventTypeCategory\n    ...genreCategory\n    ...venueCategory\n    ...programCategory\n  }\n  "): (typeof documents)["\n  fragment categories on CategoryInterface {\n    ...accessibilityCategory\n    ...eventTypeCategory\n    ...genreCategory\n    ...venueCategory\n    ...programCategory\n  }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment socialLinks on socialLinks_Entry {\n    __typename\n    facebook\n    twitter\n    instagram\n  }\n"): (typeof documents)["\n  fragment socialLinks on socialLinks_Entry {\n    __typename\n    facebook\n    twitter\n    instagram\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment address on Address {\n    __typename\n    addressLine1\n    addressLine2\n    addressLine3\n    administrativeArea\n    countryCode\n    organization\n    organizationTaxId\n    postalCode\n    sortingCode\n    latitude\n    locality\n    longitude\n    language\n    dependentLocality\n  }\n"): (typeof documents)["\n  fragment address on Address {\n    __typename\n    addressLine1\n    addressLine2\n    addressLine3\n    administrativeArea\n    countryCode\n    organization\n    organizationTaxId\n    postalCode\n    sortingCode\n    latitude\n    locality\n    longitude\n    language\n    dependentLocality\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment document on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n"): (typeof documents)["\n  fragment document on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    __typename\n    url\n    filename\n    extension\n    size\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageBranch on EntryInterface {\n    __typename\n    title\n    uri\n    id\n    children {\n      title\n      uri\n      id\n      children {\n        title\n        uri\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment pageBranch on EntryInterface {\n    __typename\n    title\n    uri\n    id\n    children {\n      title\n      uri\n      id\n      children {\n        title\n        uri\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryId on EntryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n      title\n    }\n  }\n"): (typeof documents)["\n  fragment entryId on EntryInterface {\n    __typename\n    id\n    title\n    parent {\n      id\n      title\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    title\n    sectionHandle\n    status\n    enabled\n    postDate @formatDateTime(format: \"j M, Y\")\n    parent {\n      id\n      uri\n      title\n    }\n  }\n"): (typeof documents)["\n  fragment entryBase on EntryInterface {\n    __typename\n    typeHandle\n    searchScore\n    id\n    uri\n    url\n    slug\n    title\n    sectionHandle\n    status\n    enabled\n    postDate @formatDateTime(format: \"j M, Y\")\n    parent {\n      id\n      uri\n      title\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleIndexEntry on index_Entry {\n    ...articleIndexCard\n    articleSingle {\n      ...articleCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"): (typeof documents)["\n  fragment articleIndexEntry on index_Entry {\n    ...articleIndexCard\n    articleSingle {\n      ...articleCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n    eventMultiple {\n      ...eventCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"): (typeof documents)["\n  fragment articleEntry on article_Entry {\n    ...articleCard\n    blocks {\n      ...blocks\n    }\n    eventMultiple {\n      ...eventCard\n    }\n    articleMultiple {\n      ...articleCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment homeEntry on home_Entry {\n    ...homeCard\n    blocks {\n      ...blocks\n    }\n\n    # Hero section\n    eventSingle {\n      ...eventCard\n    }\n  }\n"): (typeof documents)["\n  fragment homeEntry on home_Entry {\n    ...homeCard\n    blocks {\n      ...blocks\n    }\n\n    # Hero section\n    eventSingle {\n      ...eventCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment venueEntry on venue_Entry {\n    ...venueCard\n  }\n"): (typeof documents)["\n  fragment venueEntry on venue_Entry {\n    ...venueCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageStandardEntry on standard_Entry {\n    ...pageStandardCard\n    pagesBranch: children {\n      ...pageBranch\n    }\n    ancestors {\n      id\n      title\n      uri\n      parent {\n        id\n      }\n    }\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment pageStandardEntry on standard_Entry {\n    ...pageStandardCard\n    pagesBranch: children {\n      ...pageBranch\n    }\n    ancestors {\n      id\n      title\n      uri\n      parent {\n        id\n      }\n    }\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageOverviewEntry on overview_Entry {\n    ...pageOverviewCard\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment pageOverviewEntry on overview_Entry {\n    ...pageOverviewCard\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageExpandableEntry on expandable_Entry {\n    ...pageExpandableCard\n    blocks {\n      ...blocks\n    }\n  }\n"): (typeof documents)["\n  fragment pageExpandableEntry on expandable_Entry {\n    ...pageExpandableCard\n    blocks {\n      ...blocks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment shortlistEntry on index_Entry {\n    ...shortlistCard\n  }\n"): (typeof documents)["\n  fragment shortlistEntry on index_Entry {\n    ...shortlistCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment searchEntry on index_Entry {\n    ...searchCard\n  }\n"): (typeof documents)["\n  fragment searchEntry on index_Entry {\n    ...searchCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment notFoundEntry on notFound_Entry {\n    ...notFoundCard\n  }\n"): (typeof documents)["\n  fragment notFoundEntry on notFound_Entry {\n    ...notFoundCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventIndexEntry on index_Entry {\n    ...eventIndexCard\n  }\n"): (typeof documents)["\n  fragment eventIndexEntry on index_Entry {\n    ...eventIndexCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventEntry on event_Entry {\n    ...eventCard\n    blocks {\n      ...blocks\n    }\n    onSale\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    eventPrice {\n      ...eventPriceCard\n    }\n    eventInfo {\n      ...eventInfoCard\n    }\n    faqMultiple {\n      ...faqCard\n    }\n  }\n"): (typeof documents)["\n  fragment eventEntry on event_Entry {\n    ...eventCard\n    blocks {\n      ...blocks\n    }\n    onSale\n    programCategory {\n      ...categories\n    }\n    accessibilityCategory {\n      ...categories\n    }\n    eventTypeCategory {\n      ...categories\n    }\n    eventPrice {\n      ...eventPriceCard\n    }\n    eventInfo {\n      ...eventInfoCard\n    }\n    faqMultiple {\n      ...faqCard\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventInfoEntry on eventInfo_Entry {\n    ...eventInfoCard\n  }\n"): (typeof documents)["\n  fragment eventInfoEntry on eventInfo_Entry {\n    ...eventInfoCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventPriceEntry on eventPrice_Entry {\n    ...eventPriceCard\n  }\n"): (typeof documents)["\n  fragment eventPriceEntry on eventPrice_Entry {\n    ...eventPriceCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventTimeEntry on eventTime_Entry {\n    ...eventTimeCard\n  }\n"): (typeof documents)["\n  fragment eventTimeEntry on eventTime_Entry {\n    ...eventTimeCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entries on EntryInterface {\n    ...entryBase\n    ...entrySeo\n    ...articleIndexEntry\n    ...articleEntry\n    ...homeEntry\n    ...venueEntry\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...pageExpandableEntry\n    ...shortlistEntry\n    ...searchEntry\n    ...notFoundEntry\n\n    ...eventIndexEntry\n    ...eventEntry\n    \n    # ...faqEntry\n\n    # Event matrix entries\n    # ...eventInfoEntry\n    # ...eventPriceEntry\n    # ...eventTimeEntry\n  }\n"): (typeof documents)["\n  fragment entries on EntryInterface {\n    ...entryBase\n    ...entrySeo\n    ...articleIndexEntry\n    ...articleEntry\n    ...homeEntry\n    ...venueEntry\n    ...pageStandardEntry\n    ...pageOverviewEntry\n    ...pageExpandableEntry\n    ...shortlistEntry\n    ...searchEntry\n    ...notFoundEntry\n\n    ...eventIndexEntry\n    ...eventEntry\n    \n    # ...faqEntry\n\n    # Event matrix entries\n    # ...eventInfoEntry\n    # ...eventPriceEntry\n    # ...eventTimeEntry\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment articleIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment articleCard on article_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment articleCard on article_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment homeCard on home_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment homeCard on home_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment venueCard on venue_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment venueCard on venue_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageStandardCard on standard_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment pageStandardCard on standard_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageOverviewCard on overview_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment pageOverviewCard on overview_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment pageExpandableCard on expandable_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"): (typeof documents)["\n  fragment pageExpandableCard on expandable_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    colorScheme {\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment shortlistCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment shortlistCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment searchCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment searchCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment notFoundCard on notFound_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment notFoundCard on notFound_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment faqCard on faq_Entry {\n    heading\n    content: htmlContentDefault\n  }\n"): (typeof documents)["\n  fragment faqCard on faq_Entry {\n    heading\n    content: htmlContentDefault\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"): (typeof documents)["\n  fragment eventIndexCard on index_Entry {\n    ...entryBase\n    entryTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventCard on event_Entry {\n    ...entryBase\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    videoUrl\n    genreCategory {\n      ...categories\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n  }\n"): (typeof documents)["\n  fragment eventCard on event_Entry {\n    ...entryBase\n    entryTitle\n    entrySubTitle\n    entrySummary\n    entryImage {\n      ...image\n    }\n    videoUrl\n    genreCategory {\n      ...categories\n    }\n    eventTime {\n      ...eventTimeCard\n    }\n    eventId\n    venueCategory {\n      ...categories\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventPriceCard on eventPrice_Entry {\n    ...entryBase\n    ownerId\n    title\n    minPrice\n    maxPrice\n  }\n"): (typeof documents)["\n  fragment eventPriceCard on eventPrice_Entry {\n    ...entryBase\n    ownerId\n    title\n    minPrice\n    maxPrice\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventTimeCard on eventTime_Entry {\n    ...entryBase\n    ownerId\n    date\n    eventTimeId\n  }\n"): (typeof documents)["\n  fragment eventTimeCard on eventTime_Entry {\n    ...entryBase\n    ownerId\n    date\n    eventTimeId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment eventInfoCard on eventInfo_Entry {\n    ...entryBase\n    heading\n    content: htmlContentSimple\n  }\n"): (typeof documents)["\n  fragment eventInfoCard on eventInfo_Entry {\n    ...entryBase\n    heading\n    content: htmlContentSimple\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entryCards on EntryInterface {\n    ...articleIndexCard\n    ...articleCard\n    ...homeCard  \n    ...venueCard\n    ...pageStandardCard\n    ...pageOverviewCard\n    ...pageExpandableCard\n    ...shortlistCard\n    ...searchCard\n    ...notFoundCard\n    ...eventIndexCard\n    ...eventCard\n    # ...eventPriceCard\n    # ...eventTimeCard\n    # ...eventInfoCard\n  }\n"): (typeof documents)["\n  fragment entryCards on EntryInterface {\n    ...articleIndexCard\n    ...articleCard\n    ...homeCard  \n    ...venueCard\n    ...pageStandardCard\n    ...pageOverviewCard\n    ...pageExpandableCard\n    ...shortlistCard\n    ...searchCard\n    ...notFoundCard\n    ...eventIndexCard\n    ...eventCard\n    # ...eventPriceCard\n    # ...eventTimeCard\n    # ...eventInfoCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment formieField on FieldInterface {\n    ...fieldAddress\n    ...fieldAgree\n    ...fieldCheckboxes\n    ...fieldDropdown\n    ...fieldName\n    ...fieldRadio\n    ...fieldSingleLineText\n    ...fieldMultiLineText\n    ...fieldEmail\n    ...fieldNumber\n    ...fieldPhone\n    ...fieldFileUpload\n    ...fieldDate\n    ...formieAddressSubfield\n    ...formieNameSubfield\n    ...formieDateSubfield\n    #\n    ...fieldSection\n    ...fieldHeading\n}\n"): (typeof documents)["\n  fragment formieField on FieldInterface {\n    ...fieldAddress\n    ...fieldAgree\n    ...fieldCheckboxes\n    ...fieldDropdown\n    ...fieldName\n    ...fieldRadio\n    ...fieldSingleLineText\n    ...fieldMultiLineText\n    ...fieldEmail\n    ...fieldNumber\n    ...fieldPhone\n    ...fieldFileUpload\n    ...fieldDate\n    ...formieAddressSubfield\n    ...formieNameSubfield\n    ...formieDateSubfield\n    #\n    ...fieldSection\n    ...fieldHeading\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment formieCaptcha on FormieCaptchaType {\n    __typename\n    handle\n    name\n    value\n  }\n"): (typeof documents)["\n  fragment formieCaptcha on FormieCaptchaType {\n    __typename\n    handle\n    name\n    value\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment formieForm on FormInterface {\n    __typename\n    id\n    uid\n    handle\n    submissionMutationName\n    slug\n    rows {\n      __typename\n      id\n      rowFields {\n        ...formieField\n      }\n    }\n    settings {\n      submitActionMessageHtml\n      errorMessageHtml\n    }\n    rows {\n      id\n      rowFields {\n        id\n      }\n    }\n    captchas {\n      ...formieCaptcha\n    }\n  }\n"): (typeof documents)["\n  fragment formieForm on FormInterface {\n    __typename\n    id\n    uid\n    handle\n    submissionMutationName\n    slug\n    rows {\n      __typename\n      id\n      rowFields {\n        ...formieField\n      }\n    }\n    settings {\n      submitActionMessageHtml\n      errorMessageHtml\n    }\n    rows {\n      id\n      rowFields {\n        id\n      }\n    }\n    captchas {\n      ...formieCaptcha\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAddress1 on Field_Address1 {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldAddress1 on Field_Address1 {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAddress2 on Field_Address2 {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldAddress2 on Field_Address2 {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAddress3 on Field_Address3 {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldAddress3 on Field_Address3 {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAddressCity on Field_AddressCity {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldAddressCity on Field_AddressCity {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAddressCountry on Field_AddressCountry {\n    ...fieldBase\n    options {\n      ...fieldOption\n    }\n  }\n"): (typeof documents)["\n  fragment fieldAddressCountry on Field_AddressCountry {\n    ...fieldBase\n    options {\n      ...fieldOption\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAddressState on Field_AddressState {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldAddressState on Field_AddressState {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAddressZip on Field_AddressZip {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldAddressZip on Field_AddressZip {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldNameFirst on Field_NameFirst {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldNameFirst on Field_NameFirst {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldNameMiddle on Field_NameMiddle {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldNameMiddle on Field_NameMiddle {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldNameLast on Field_NameLast {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldNameLast on Field_NameLast {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldNamePrefix on Field_NamePrefix {\n    ...fieldBase\n    options {\n      ...fieldOption\n    }\n  }\n"): (typeof documents)["\n  fragment fieldNamePrefix on Field_NamePrefix {\n    ...fieldBase\n    options {\n      ...fieldOption\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment formieAddressSubfield on FieldInterface {\n    ...fieldAddress1\n    ...fieldAddress2\n    ...fieldAddress3\n    ...fieldAddressCity\n    ...fieldAddressCountry\n    ...fieldAddressState\n    ...fieldAddressZip\n  }\n"): (typeof documents)["\n  fragment formieAddressSubfield on FieldInterface {\n    ...fieldAddress1\n    ...fieldAddress2\n    ...fieldAddress3\n    ...fieldAddressCity\n    ...fieldAddressCountry\n    ...fieldAddressState\n    ...fieldAddressZip\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment formieNameSubfield on FieldInterface {\n    ...fieldNameFirst\n    ...fieldNameMiddle\n    ...fieldNameLast\n    ...fieldNamePrefix\n  }\n"): (typeof documents)["\n  fragment formieNameSubfield on FieldInterface {\n    ...fieldNameFirst\n    ...fieldNameMiddle\n    ...fieldNameLast\n    ...fieldNamePrefix\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAddress on Field_Address {\n    ...fieldBase\n    nestedRows {\n      __typename\n      id\n      rowFields {\n        ...formieAddressSubfield\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment fieldAddress on Field_Address {\n    ...fieldBase\n    nestedRows {\n      __typename\n      id\n      rowFields {\n        ...formieAddressSubfield\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAgree on Field_Agree {\n    ...fieldBase\n    uncheckedValue\n    checkedValue\n    inputTypeName\n    descriptionHtml\n    prePopulate\n    defaultState\n  }\n"): (typeof documents)["\n  fragment fieldAgree on Field_Agree {\n    ...fieldBase\n    uncheckedValue\n    checkedValue\n    inputTypeName\n    descriptionHtml\n    prePopulate\n    defaultState\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldCheckboxes on Field_Checkboxes {\n    ...fieldBase\n    multi\n    layout\n    options {\n      ...fieldOption\n    }\n  }\n"): (typeof documents)["\n  fragment fieldCheckboxes on Field_Checkboxes {\n    ...fieldBase\n    multi\n    layout\n    options {\n      ...fieldOption\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldDropdown on Field_Dropdown {\n    ...fieldBase\n    multi\n    options {\n      ...fieldOption\n    }\n  }\n"): (typeof documents)["\n  fragment fieldDropdown on Field_Dropdown {\n    ...fieldBase\n    multi\n    options {\n      ...fieldOption\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldName on Field_Name {\n    ...fieldBase\n    useMultipleFields\n    nestedRows {\n      __typename\n      id\n      rowFields {\n        ...formieNameSubfield\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment fieldName on Field_Name {\n    ...fieldBase\n    useMultipleFields\n    nestedRows {\n      __typename\n      id\n      rowFields {\n        ...formieNameSubfield\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldRadio on Field_Radio {\n    ...fieldBase\n    layout\n    options {\n      ...fieldOption\n    }\n  }\n"): (typeof documents)["\n  fragment fieldRadio on Field_Radio {\n    ...fieldBase\n    layout\n    options {\n      ...fieldOption\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment fieldSingleLineText on Field_SingleLineText {\n    ...fieldBase\n  }\n"): (typeof documents)["\nfragment fieldSingleLineText on Field_SingleLineText {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldMultiLineText on Field_MultiLineText {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldMultiLineText on Field_MultiLineText {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldEmail on Field_Email {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldEmail on Field_Email {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldNumber on Field_Number {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldNumber on Field_Number {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldPhone on Field_Phone {\n    ...fieldBase\n  }\n"): (typeof documents)["\n  fragment fieldPhone on Field_Phone {\n    ...fieldBase\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldFileUpload on Field_FileUpload {\n    ...fieldBase\n    allowedKinds\n    limitFiles\n    sizeLimit\n    sizeMinLimit\n  }\n"): (typeof documents)["\n  fragment fieldFileUpload on Field_FileUpload {\n    ...fieldBase\n    allowedKinds\n    limitFiles\n    sizeLimit\n    sizeMinLimit\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment formieDateSubfield on FieldInterface {\n    # ...fieldDateYearDropdown\n    # ...fieldDateAmPmNumber\n    # ...fieldDateSecondNumber\n    # ...fieldDateMinuteNumber\n    # ...fieldDateHourNumber\n    # ...fieldDateDayNumber\n    # ...fieldDateMonthNumber\n    # ...fieldDateYearNumber\n    # ...fieldDateAmPmDropdown\n    # ...fieldDateSecondDropdown\n    # ...fieldDateMinuteDropdown\n    # ...fieldDateHourDropdown\n    # ...fieldDateDayDropdown\n    # ...fieldDateMonthDropdown\n    ...fieldSingleLineText\n}\n"): (typeof documents)["\n  fragment formieDateSubfield on FieldInterface {\n    # ...fieldDateYearDropdown\n    # ...fieldDateAmPmNumber\n    # ...fieldDateSecondNumber\n    # ...fieldDateMinuteNumber\n    # ...fieldDateHourNumber\n    # ...fieldDateDayNumber\n    # ...fieldDateMonthNumber\n    # ...fieldDateYearNumber\n    # ...fieldDateAmPmDropdown\n    # ...fieldDateSecondDropdown\n    # ...fieldDateMinuteDropdown\n    # ...fieldDateHourDropdown\n    # ...fieldDateDayDropdown\n    # ...fieldDateMonthDropdown\n    ...fieldSingleLineText\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldDate on Field_Date {\n    ...fieldBase\n    defaultDate\n    fields {\n      ...formieDateSubfield\n    }\n  }\n"): (typeof documents)["\n  fragment fieldDate on Field_Date {\n    ...fieldBase\n    defaultDate\n    fields {\n      ...formieDateSubfield\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldBase on FieldInterface {\n    __typename\n    id\n    conditions\n    containerAttributes { label, value}\n    cssClasses\n    defaultValue\n    displayName\n    emailValue\n    enableConditions\n    enabled\n    errorMessage\n    handle\n    inputAttributes { label, value }\n    inputTypeName\n    instructions\n    label\n    labelPosition\n    matchField\n    placeholder\n    prePopulate\n    required\n    type\n    typeName\n    uid\n    visibility\n  }"): (typeof documents)["\n  fragment fieldBase on FieldInterface {\n    __typename\n    id\n    conditions\n    containerAttributes { label, value}\n    cssClasses\n    defaultValue\n    displayName\n    emailValue\n    enableConditions\n    enabled\n    errorMessage\n    handle\n    inputAttributes { label, value }\n    inputTypeName\n    instructions\n    label\n    labelPosition\n    matchField\n    placeholder\n    prePopulate\n    required\n    type\n    typeName\n    uid\n    visibility\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldOption on FieldOption {\n    __typename\n    value\n    label\n    isDefault\n  }\n"): (typeof documents)["\n  fragment fieldOption on FieldOption {\n    __typename\n    value\n    label\n    isDefault\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldAttribute on FieldAttribute {\n    __typename\n    value\n    label\n  }\n"): (typeof documents)["\n  fragment fieldAttribute on FieldAttribute {\n    __typename\n    value\n    label\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldHeading on Field_Heading {\n    __typename\n    id\n    label\n    headingSize\n    conditions\n    enableConditions\n  }\n"): (typeof documents)["\n  fragment fieldHeading on Field_Heading {\n    __typename\n    id\n    label\n    headingSize\n    conditions\n    enableConditions\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fieldSection on Field_Section {\n    __typename\n    id\n    label\n    conditions\n    enableConditions\n  }\n"): (typeof documents)["\n  fragment fieldSection on Field_Section {\n    __typename\n    id\n    label\n    conditions\n    enableConditions\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalBase on GlobalSetInterface {\n    __typename\n    id\n    handle\n  }\n  "): (typeof documents)["\n  fragment globalBase on GlobalSetInterface {\n    __typename\n    id\n    handle\n  }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n  }\n"): (typeof documents)["\n  fragment globalCopyright on copyright_GlobalSet {\n    label\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalCompanyInformation on companyInformation_GlobalSet {\n    content: htmlContentDefault\n  }\n"): (typeof documents)["\n  fragment globalCompanyInformation on companyInformation_GlobalSet {\n    content: htmlContentDefault\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalShoppingBasket on shoppingBasket_GlobalSet {\n    externalUrl\n  }\n"): (typeof documents)["\n  fragment globalShoppingBasket on shoppingBasket_GlobalSet {\n    externalUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalProfile on profile_GlobalSet {\n    externalUrl\n  }\n"): (typeof documents)["\n  fragment globalProfile on profile_GlobalSet {\n    externalUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globalSocialLinks on socialLinks_GlobalSet {\n    socialLinks {\n      ...socialLinks\n    }\n  }\n"): (typeof documents)["\n  fragment globalSocialLinks on socialLinks_GlobalSet {\n    socialLinks {\n      ...socialLinks\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment globals on GlobalSetInterface {\n    ...globalBase\n    ...globalCopyright\n    ...globalCompanyInformation\n    ...globalSocialLinks\n    ...globalShoppingBasket\n    ...globalProfile\n  }\n"): (typeof documents)["\n  fragment globals on GlobalSetInterface {\n    ...globalBase\n    ...globalCopyright\n    ...globalCompanyInformation\n    ...globalSocialLinks\n    ...globalShoppingBasket\n    ...globalProfile\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_original on AssetInterface {\n    url \n    height \n    width \n    original_url: url\n    original_height: width\n    original_width: height\n  }\n"): (typeof documents)["\n  fragment imageSize_original on AssetInterface {\n    url \n    height \n    width \n    original_url: url\n    original_height: width\n    original_width: height\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_fullFit on AssetInterface {\n    fullFit_url: url\n    fullFit_height: width\n    fullFit_width: height\n  }\n"): (typeof documents)["\n  fragment imageSize_fullFit on AssetInterface {\n    fullFit_url: url\n    fullFit_height: width\n    fullFit_width: height\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment imageSize_all on AssetInterface {\n    ...imageSize_original\n    ...imageSize_fullFit\n  }\n"): (typeof documents)["\n  fragment imageSize_all on AssetInterface {\n    ...imageSize_original\n    ...imageSize_fullFit\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment image on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    ...imageSize_all\n  }\n"): (typeof documents)["\n  fragment image on AssetInterface {\n    mimeType\n    id\n    alt\n    title\n    ...imageSize_all\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldUrl on linkField_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldUrl on linkField_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldEntry on linkField_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldEntry on linkField_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldCustom on linkField_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldCustom on linkField_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldEmail on linkField_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldEmail on linkField_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldPhone on linkField_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldPhone on linkField_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldCustom\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n"): (typeof documents)["\n  fragment linkField on HyperLinkInterface {\n    ...linkFieldUrl\n    ...linkFieldEntry\n    ...linkFieldCustom\n    ...linkFieldEmail\n    ...linkFieldPhone\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultipleUrl on linkFieldMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultipleUrl on linkFieldMultiple_Url_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultipleEntry on linkFieldMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultipleEntry on linkFieldMultiple_Entry_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultipleCustom on linkFieldMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultipleCustom on linkFieldMultiple_Custom_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultipleEmail on linkFieldMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultipleEmail on linkFieldMultiple_Email_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultiplePhone on linkFieldMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"): (typeof documents)["\n  fragment linkFieldMultiplePhone on linkFieldMultiple_Phone_LinkType {\n    __typename\n    target\n    text\n    newWindow\n    title\n    type\n    url\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment linkFieldMultiple on HyperLinkInterface {\n    ...linkFieldMultipleUrl\n    ...linkFieldMultipleEntry\n    ...linkFieldMultipleCustom\n    ...linkFieldMultipleEmail\n    ...linkFieldMultiplePhone\n  }\n"): (typeof documents)["\n  fragment linkFieldMultiple on HyperLinkInterface {\n    ...linkFieldMultipleUrl\n    ...linkFieldMultipleEntry\n    ...linkFieldMultipleCustom\n    ...linkFieldMultipleEmail\n    ...linkFieldMultiplePhone\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment navigationNode on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n      uri\n      language\n    }\n  }\n"): (typeof documents)["\n  fragment navigationNode on NodeInterface {\n    __typename\n    id\n    uri\n    url\n    level\n    title\n    newWindow\n    typeLabel\n    classes\n    parent {\n      id\n    }\n    element {\n      uri\n      language\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n"): (typeof documents)["\n  fragment seoMetaLinks on SeomaticInterface {\n    __typename\n    metaLinkContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n"): (typeof documents)["\n  fragment seoMetaTags on SeomaticInterface {\n    __typename\n    metaTagContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n"): (typeof documents)["\n  fragment seoTitle on SeomaticInterface {\n    __typename\n    metaTitleContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n"): (typeof documents)["\n  fragment seoScripts on SeomaticInterface {\n    __typename\n    metaScriptContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n"): (typeof documents)["\n  fragment seoJsonLd on SeomaticInterface {\n    __typename\n    metaJsonLdContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n"): (typeof documents)["\n  fragment seoSiteVars on SeomaticInterface {\n    __typename\n    metaSiteVarsContainer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      metaTitleContainer\n      metaJsonLdContainer\n      metaLinkContainer\n      metaTagContainer\n      metaScriptContainer\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      metaSiteVarsContainer\n    }\n  }\n"): (typeof documents)["\n  fragment entrySeo on EntryInterface {\n    seoJson: seomatic(\n      asArray: true,\n    ) {\n      metaTitleContainer\n      metaJsonLdContainer\n      metaLinkContainer\n      metaTagContainer\n      metaScriptContainer\n    }\n    seoHtml: seomatic(\n      asArray: false,\n    ) {\n      metaSiteVarsContainer\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query categories {\n    accessibilityCategory: categories(group: \"accessibilityCategory\") {\n      ...categories\n    }\n    eventTypeCategory: categories(group: \"eventTypeCategory\") {\n      ...categories\n    }\n    genreCategory: categories(group: \"genreCategory\") {\n      ...categories\n    }\n    locationCategory: categories(group: \"locationCategory\") {\n      ...categories\n    }\n    programCategory: categories(group: \"programCategory\") {\n      ...categories\n    }\n  }\n"): (typeof documents)["\n  query categories {\n    accessibilityCategory: categories(group: \"accessibilityCategory\") {\n      ...categories\n    }\n    eventTypeCategory: categories(group: \"eventTypeCategory\") {\n      ...categories\n    }\n    genreCategory: categories(group: \"genreCategory\") {\n      ...categories\n    }\n    locationCategory: categories(group: \"locationCategory\") {\n      ...categories\n    }\n    programCategory: categories(group: \"programCategory\") {\n      ...categories\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query category($group: [String]) {\n    category: categories(group: $group) {\n      ...categories\n    }\n  }\n"): (typeof documents)["\n  query category($group: [String]) {\n    category: categories(group: $group) {\n      ...categories\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment commonLink on EntryInterface {\n    id\n    uri\n    title\n  }\n"): (typeof documents)["\n  fragment commonLink on EntryInterface {\n    id\n    uri\n    title\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query commonLinks($site: [String] = \"default\") {\n    home: entry(site: $site, uri: \"__home__\") {\n      ...commonLink\n    }\n    search: entry(site: $site, section: \"search\") {\n      ...commonLink\n    }\n    articleIndex: entry(site: $site, section: \"articleIndex\") {\n      ...commonLink\n    }\n    eventIndex: entry(site: $site, section: \"page\", type: \"index\") {\n      ...commonLink\n    }\n  }\n"): (typeof documents)["\n    query commonLinks($site: [String] = \"default\") {\n    home: entry(site: $site, uri: \"__home__\") {\n      ...commonLink\n    }\n    search: entry(site: $site, section: \"search\") {\n      ...commonLink\n    }\n    articleIndex: entry(site: $site, section: \"articleIndex\") {\n      ...commonLink\n    }\n    eventIndex: entry(site: $site, section: \"page\", type: \"index\") {\n      ...commonLink\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query listEntries(\n    $site: [String] = \"default\",\n    $limit: Int,\n    $section: [String],\n    $type: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n    $relatedToCategories: [CategoryRelationCriteriaInput] = null\n  ) {\n    entries(\n      site: $site,\n      relatedToEntries: $relatedToEntries\n      relatedToCategories: $relatedToCategories\n      limit: $limit\n      section: $section\n      orderBy: $orderBy,\n      type: $type\n    ) {\n      ...entryId\n    }\n  }\n"): (typeof documents)["\n  query listEntries(\n    $site: [String] = \"default\",\n    $limit: Int,\n    $section: [String],\n    $type: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n    $relatedToCategories: [CategoryRelationCriteriaInput] = null\n  ) {\n    entries(\n      site: $site,\n      relatedToEntries: $relatedToEntries\n      relatedToCategories: $relatedToCategories\n      limit: $limit\n      section: $section\n      orderBy: $orderBy,\n      type: $type\n    ) {\n      ...entryId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query listCategories(\n    $site: [String] = \"default\",\n    $group: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n  ) {\n    categories(\n      site: $site,\n      group: $group,\n      orderBy: $orderBy,\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...categoryId\n    }\n  }\n"): (typeof documents)["\n  query listCategories(\n    $site: [String] = \"default\",\n    $group: [String],\n    $orderBy: String,\n    $relatedToEntries: [EntryRelationCriteriaInput] = null\n  ) {\n    categories(\n      site: $site,\n      group: $group,\n      orderBy: $orderBy,\n      relatedToEntries: $relatedToEntries\n    ) {\n      ...categoryId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query searchEntries(\n      $site: [String] = \"default\",\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput],\n      $fixedOrder: Boolean\n      $id: [QueryArgument]\n    ) {\n    entries(\n      site: $site,\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      fixedOrder: $fixedOrder\n      id: $id\n    ) {\n      ...entryCards\n    }\n    entryCount(\n      site: $site,\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      id: $id\n    )\n  }\n"): (typeof documents)["\n  query searchEntries(\n      $site: [String] = \"default\",\n      $limit: Int = 12,\n      $offset: Int = 0,\n      $section: [String],\n      $type: [String],\n      $search: String,\n      $orderBy: String,\n      $relatedToCategories: [CategoryRelationCriteriaInput],\n      $relatedToEntries: [EntryRelationCriteriaInput],\n      $fixedOrder: Boolean\n      $id: [QueryArgument]\n    ) {\n    entries(\n      site: $site,\n      limit: $limit\n      offset: $offset\n      section: $section\n      type: $type\n      search: $search\n      orderBy: $orderBy\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      fixedOrder: $fixedOrder\n      id: $id\n    ) {\n      ...entryCards\n    }\n    entryCount(\n      site: $site,\n      section: $section\n      type: $type\n      search: $search\n      relatedToCategories: $relatedToCategories\n      relatedToEntries: $relatedToEntries\n      id: $id\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query eventPriceEntries {\n        eventPriceFieldEntries {\n            ...eventPriceCard\n        } \n    }\n"): (typeof documents)["\n    query eventPriceEntries {\n        eventPriceFieldEntries {\n            ...eventPriceCard\n        } \n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query eventTimeEntries($date: [QueryArgument]) {\n        eventTimeFieldEntries(date: $date, orderBy: \"date ASC\") {\n            ...eventTimeCard\n        } \n    }\n"): (typeof documents)["\n    query eventTimeEntries($date: [QueryArgument]) {\n        eventTimeFieldEntries(date: $date, orderBy: \"date ASC\") {\n            ...eventTimeCard\n        } \n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query eventIndexEntries(\n        $genreCategory: [QueryArgument],\n        $venueCategory: [QueryArgument],\n        $accessibilityCategory: [QueryArgument],\n        $programCategory: [QueryArgument],\n        $eventTypeCategory: [QueryArgument],\n        $id: [QueryArgument],\n        $orderBy: String\n      ) {\n        entries(genreCategory: $genreCategory, venueCategory: $venueCategory, accessibilityCategory: $accessibilityCategory, programCategory: $programCategory, eventTypeCategory: $eventTypeCategory, id: $id, orderBy: $orderBy) {\n            ...entries\n        }\n    }\n"): (typeof documents)["\n    query eventIndexEntries(\n        $genreCategory: [QueryArgument],\n        $venueCategory: [QueryArgument],\n        $accessibilityCategory: [QueryArgument],\n        $programCategory: [QueryArgument],\n        $eventTypeCategory: [QueryArgument],\n        $id: [QueryArgument],\n        $orderBy: String\n      ) {\n        entries(genreCategory: $genreCategory, venueCategory: $venueCategory, accessibilityCategory: $accessibilityCategory, programCategory: $programCategory, eventTypeCategory: $eventTypeCategory, id: $id, orderBy: $orderBy) {\n            ...entries\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query formExists($uid: [String]!) {\n    formieForm(uid: $uid) {\n      id\n      handle\n      submissionMutationName\n    }\n  }\n"): (typeof documents)["\n  query formExists($uid: [String]!) {\n    formieForm(uid: $uid) {\n      id\n      handle\n      submissionMutationName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query globals($handle: [String]) {\n    globalSets(handle: $handle) {\n      ...globals\n    }\n  }\n"): (typeof documents)["\n  query globals($handle: [String]) {\n    globalSets(handle: $handle) {\n      ...globals\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query navMenus {\n    primaryNav: navigationNodes(navHandle: \"primaryNav\") {\n      ...navigationNode\n    }\n    secondaryNav: navigationNodes(navHandle: \"secondaryNav\") {\n      ...navigationNode\n    }\n    footerNav: navigationNodes(navHandle: \"footerNav\") {\n      ...navigationNode\n    }\n    footerExtrasNav: navigationNodes(navHandle: \"footerExtrasNav\") {\n      ...navigationNode\n    }\n    auxiliaryNav: navigationNodes(navHandle: \"auxiliaryNav\") {\n      ...navigationNode\n    }\n    policiesNav: navigationNodes(navHandle: \"policiesNav\") {\n      ...navigationNode\n    }\n  }\n"): (typeof documents)["\n  query navMenus {\n    primaryNav: navigationNodes(navHandle: \"primaryNav\") {\n      ...navigationNode\n    }\n    secondaryNav: navigationNodes(navHandle: \"secondaryNav\") {\n      ...navigationNode\n    }\n    footerNav: navigationNodes(navHandle: \"footerNav\") {\n      ...navigationNode\n    }\n    footerExtrasNav: navigationNodes(navHandle: \"footerExtrasNav\") {\n      ...navigationNode\n    }\n    auxiliaryNav: navigationNodes(navHandle: \"auxiliaryNav\") {\n      ...navigationNode\n    }\n    policiesNav: navigationNodes(navHandle: \"policiesNav\") {\n      ...navigationNode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query navMenu($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      ...navigationNode\n    }\n  }\n"): (typeof documents)["\n  query navMenu($navHandle: String!) {\n    nav: navigationNodes(navHandle: $navHandle) {\n      ...navigationNode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query allUris(\n    $section: [String], \n    $type: [String], \n    $orderBy: String, \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entries(section: $section, type: $type, orderBy: $orderBy, status: $status) {\n      id\n      uri\n      slug\n    }\n  }\n"): (typeof documents)["\n  query allUris(\n    $section: [String], \n    $type: [String], \n    $orderBy: String, \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entries(section: $section, type: $type, orderBy: $orderBy, status: $status) {\n      id\n      uri\n      slug\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    $status: [String] = [\"live\"]\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy, status: $status) {\n      ...entries\n    }\n  }\n"): (typeof documents)["\n  query contentByUri(\n    $site: [String] = \"default\"\n    $uri: [String]\n    $slug: [String]\n    $section: [String]\n    $orderBy: String\n    $status: [String] = [\"live\"]\n    # $seoEnvironment: SeomaticEnvironment\n  ) {\n    entry(site: $site, uri: $uri, slug: $slug, section: $section, orderBy: $orderBy, status: $status) {\n      ...entries\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $descendantOf: Int\n    $descendantDist: Int\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, descendantOf: $descendantOf, descendantDist: $descendantDist, status: $status) {\n      ...entries\n    }\n  }\n"): (typeof documents)["\n  query contentByType(\n    $site: [String] = \"default\"\n    $section: [String]\n    $type: [String]\n    $orderBy: String\n    $limit: Int\n    $descendantOf: Int\n    $descendantDist: Int\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(site: $site, section: $section, type: $type, orderBy: $orderBy, limit: $limit, descendantOf: $descendantOf, descendantDist: $descendantDist, status: $status) {\n      ...entries\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query entryExists(\n    $uri: [String], \n    $slug: [String], \n    $id: [QueryArgument], \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entry(uri: $uri, id: $id, slug: $slug, status: $status, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n"): (typeof documents)["\n  query entryExists(\n    $uri: [String], \n    $slug: [String], \n    $id: [QueryArgument], \n    $status: [String] = [\"live\", \"expired\"]\n    ) {\n    entry(uri: $uri, id: $id, slug: $slug, status: $status, limit: 1) {\n      __typename\n      id\n      uri\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query relatedEntries(\n    $site: [String] = \"default\"\n    $exclude: QueryArgument\n    $section: [String]\n    $categoryId: [QueryArgument]\n    $parentId: Int\n    $orderBy: String\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(\n      site: $site\n      limit: 4\n      id: [\"not\", $exclude]\n      relatedTo: $categoryId\n      section: $section\n      orderBy: $orderBy\n      descendantOf: $parentId\n      status: $status\n    ) {\n      ...entryCards\n    }\n  }\n"): (typeof documents)["\n  query relatedEntries(\n    $site: [String] = \"default\"\n    $exclude: QueryArgument\n    $section: [String]\n    $categoryId: [QueryArgument]\n    $parentId: Int\n    $orderBy: String\n    $status: [String] = [\"live\", \"expired\"]\n  ) {\n    entries(\n      site: $site\n      limit: 4\n      id: [\"not\", $exclude]\n      relatedTo: $categoryId\n      section: $section\n      orderBy: $orderBy\n      descendantOf: $parentId\n      status: $status\n    ) {\n      ...entryCards\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query allImportedEvents(\n    $orderBy: String, \n    $status: [String]\n    ) {\n    eventEntries(orderBy: $orderBy, status: $status) {\n      ... on event_Entry {\n        title\n        eventId\n      }\n    }\n  }\n"): (typeof documents)["\n  query allImportedEvents(\n    $orderBy: String, \n    $status: [String]\n    ) {\n    eventEntries(orderBy: $orderBy, status: $status) {\n      ... on event_Entry {\n        title\n        eventId\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;
import { Category } from '@/lib/parsers/categories';
import { ImageFragmentTypes, parseImage } from '@/lib/parsers/images';
import useShortlistStore from '@/lib/store/shortlist';
import { useNextHydrated } from '@/lib/utils/createToggleStore';
import { fmtCategoryNames, fmtEventTime } from '@/lib/utils/format';
import { parseUri } from '@liquorice/utils';
import { useTranslations } from 'next-intl';
import Card from '../Card';
import PageTicket from '../PageTicket';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import * as styles from './EntryCard.css';
import { EntryCardProps } from './EntryCardProps';

export type EventCardProps = EntryCardProps<'event'>;

export const EventCard = ({ item, ExtraProps, ...props }: EventCardProps) => {
  const t = useTranslations('common');
  const { uri, entryTitle, entryImage, eventTime, status } = item;
  const { customText } = ExtraProps ?? {};

  // FIX ME
  const genreCategory = item.genreCategory as unknown as Category<'genreCategory'>;
  const genre = fmtCategoryNames(genreCategory);
  const dateRange = fmtEventTime(eventTime);
  const toggleEvent = useShortlistStore((s) => s.toggleEvent);
  const disabled = status === 'expired';
  const href = parseUri(uri);
  const image = parseImage(entryImage as MaybeArrayOf<ImageFragmentTypes>);

  const nextHydrated = useNextHydrated();

  return (
    nextHydrated && (
      <Card
        className={styles.eventCard}
        disableLinkWrap
        elevation={false}
        fullHeight
        colorSet="white"
        paper
        {...props}
        item={{
          href,
          title: entryTitle,
          image,
          meta: genre,
          description: dateRange,
          customText,
          disabled,
        }}>
        <Box cx={{ position: 'relative' }}>
          <Card.Meta className={styles.tag} color="primary" monoFont uppercase variant="tiny" />
          <Card.Heart
            itemID={item.id}
            onClick={() => toggleEvent(item)}
            className={styles.heart}
            cx={{ m: '3xs' }}
          />
          <Card.Disabled
            className={styles.disabled}
            color="white"
            monoFont
            uppercase
            variant="tiny"
          />
          <Card.Image ratio="landscape" enableLinkWrap />
        </Box>
        <Card.Body
          className={styles.eventBody({ withTransition: !disabled })}
          colorSet="white"
          paper>
          <Card.Title
            as="div"
            variant="h6"
            cx={{ fontFamily: 'body', fontWeight: 'bold', mY: '4xs' }}
          />
          <Card.Description as="span" variant="tiny" monoFont weight="regular" uppercase />
          <Box colorSet="white" paper className={styles.eventCta}>
            <Box cx={{ width: '40' }}>
              <Btn
                as="a"
                href={href}
                variant="outlined"
                color="primary"
                size="tiny"
                weight="bold"
                fullWidth>
                {t('moreInfo')}
              </Btn>
            </Box>
            <PageTicket
              cx={{ width: 'full' }}
              parentEntry={item}
              TicketBtnProps={{
                color: 'primary',
                size: 'tiny',
                fullWidth: true,
                weight: 'bold',
              }}
            />
          </Box>
        </Card.Body>
      </Card>
    )
  );
};

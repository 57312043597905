import { ADDRESS_BASE_FRAGMENT } from '@/gql/fragments/common.gql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { firstNonNullable } from '@liquorice/utils';

export type Address = {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  administrativeArea?: string;
  countryCode?: string;
  // organizatio?n:string
  // organizationTaxI?d:string
  postalCode?: string;
  locality?: string;
};

export const parseAddress = createFragmentArrayParser(ADDRESS_BASE_FRAGMENT, (data) => {
  const address = firstNonNullable(data);
  if (!address) return null;

  const {
    addressLine1,
    addressLine2,
    addressLine3,
    administrativeArea,
    countryCode,
    // organization,
    // organizationTaxId,
    postalCode,
    locality,
  } = address ?? {};

  return {
    addressLine1,
    addressLine2,
    addressLine3,
    administrativeArea,
    countryCode,
    // organization,
    // organizationTaxId,
    postalCode,
    locality,
  };
});

import { makeNonNullableArray, toString } from '@liquorice/utils';
import { FormieCaptchaFragment } from '__generated__/graphql';

export const supportedKinds = ['recaptchaCaptcha'] as const;

export type FormieCaptchaType = (typeof supportedKinds)[number];

export type FormieCaptchas = {
  [P in FormieCaptchaType]?: {
    handle: FormieCaptchaType;
    name: string;
    value: string;
  };
};

export default function parseFormieCaptchas(data: MaybeArrayOf<FormieCaptchaFragment>) {
  return makeNonNullableArray(data).reduce((acc, captcha) => {
    const handle = toString(captcha.handle);

    if (!supportedKinds.includes(handle as FormieCaptchaType)) return acc;

    return {
      ...acc,
      [handle]: {
        handle,
        name: toString(captcha.name),
        value: toString(captcha.value),
      },
    };
  }, {} as FormieCaptchas);
}

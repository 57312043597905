import { SOCIAL_LINKS_FRAGMENT } from '@/gql/fragments/common.gql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { PossibleTypes } from '@liquorice/types';
import { firstNonNullable, toStringOrNull } from '@liquorice/utils';
import { SocialLinksFragment } from '__generated__/graphql';

export type SocialLinks = {
  facebook?: string | null;
  twitter?: string | null;
  instagram?: string | null;
};

export type SocialLinkFragmentTypes = PossibleTypes<SocialLinksFragment>;

export const parseSocialLinksFragment = createFragmentArrayParser(
  SOCIAL_LINKS_FRAGMENT,
  (data): SocialLinks => {
    const res = firstNonNullable(data);
    return {
      facebook: toStringOrNull(res?.facebook),
      twitter: toStringOrNull(res?.twitter),
      instagram: toStringOrNull(res?.instagram),
    };
  }
);

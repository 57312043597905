import { gql } from '__generated__';

export const SEO_META_LINKS_FRAGMENT = gql(`
  fragment seoMetaLinks on SeomaticInterface {
    __typename
    metaLinkContainer
  }
`);

export const SEO_META_TAGS_FRAGMENT = gql(`
  fragment seoMetaTags on SeomaticInterface {
    __typename
    metaTagContainer
  }
`);

export const SEO_TITLE_FRAGMENT = gql(`
  fragment seoTitle on SeomaticInterface {
    __typename
    metaTitleContainer
  }
`);

export const SEO_SCRIPTS_FRAGMENT = gql(`
  fragment seoScripts on SeomaticInterface {
    __typename
    metaScriptContainer
  }
`);

export const SEO_JSON_LD_FRAGMENT = gql(`
  fragment seoJsonLd on SeomaticInterface {
    __typename
    metaJsonLdContainer
  }
`);
export const SEO_SITE_VARS_FRAGMENT = gql(`
  fragment seoSiteVars on SeomaticInterface {
    __typename
    metaSiteVarsContainer
  }
`);

export const ENTRY_SEO_FRAGMENT = gql(`
  fragment entrySeo on EntryInterface {
    seoJson: seomatic(
      asArray: true,
    ) {
      metaTitleContainer
      metaJsonLdContainer
      metaLinkContainer
      metaTagContainer
      metaScriptContainer
    }
    seoHtml: seomatic(
      asArray: false,
    ) {
      metaSiteVarsContainer
    }
  }
`);

import { slugify } from '@liquorice/utils';
import { ImageProps } from '.';
import Box from '../ui/Box';
import * as styles from './Image.css';

export type ImageMaskProps = ImageProps;

export const ImageMask = (props: ImageMaskProps) => {
  const { src, title } = props;

  if (!src || !title) return null;

  return (
    <Box as="figure" className={styles.maskImageWrap}>
      <svg className={styles.maskImage} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 221 258">
        <defs>
          <pattern id={'mask-' + slugify(title)} width="1" height="1" x="0" y="0">
            <image xlinkHref={src} height="100%" />
          </pattern>
        </defs>
        <g fill={`url(#mask-${slugify(title)})`}>
          <path d="M217.802 0H3.198A109.885 109.885 0 0 0 0 26.298C0 87.089 49.473 136.37 110.499 136.37c20.303 0 39.308-5.478 55.657-14.996l-107.12 84.022L100.64 258l111.865-87.74-41.188-52.074C201.244 98.491 221 64.701 221 26.298 221 17.23 219.877 8.43 217.802 0z" />
        </g>
      </svg>
    </Box>
  );
};

export default ImageMask;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMAGE_FRAGMENT } from '@/gql/fragments/images.gql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { PossibleTypes } from '@liquorice/types';
import {
  deleteUndefined,
  firstNonNullable,
  makeNonNullableArray,
  toString,
} from '@liquorice/allsorts-craftcms-nextjs';
import { ImageFragment } from '__generated__/graphql';

export type ImageTransform = {
  url: string;
  height: number;
  width: number;
};

// export type ImageTransformType = Extract<
//   keyof ImageSize_AllFragment,
//   `${string}_url`
// > extends `${infer Size}_url`
//   ? Size
//   : never;

export type ImageTransformType = 'original' | 'fullFit';

export type ImageTransforms = { [P in ImageTransformType]?: ImageTransform };

export const imageTransformKeys: ImageTransformType[] = ['original', 'fullFit'];

export interface ImageEntry {
  id?: string;
  noCrop?: boolean;
  mimeType: string;
  src: string;
  alt?: string;
  title?: string;
  caption?: string;
  height?: number;
  width?: number;
  srcSet?: string;
  transforms?: ImageTransforms;
}

export type ImageFragmentTypes = PossibleTypes<ImageFragment>;

export const parseImage = (data: MaybeArrayOf<ImageFragmentTypes>): ImageEntry | null => {
  const image = firstNonNullable(data);

  const { id, alt, title, url: src, height, width, mimeType } = (image || {}) as any;

  if (!src) return null;

  const imageEntry: ImageEntry = {
    id,
    mimeType: toString(mimeType),
    src,
    height,
    width,
    alt,
    title,
    // caption,
    // transforms: imageTransformKeys.reduce((result, transformType) => {
    //   const url = image[`${transformType}_url`];
    //   const width = toNumber(image[`${transformType}_width`]);
    //   const height = toNumber(image[`${transformType}_height`]);

    //   if (url)
    //     result[transformType] = {
    //       url,
    //       width,
    //       height,
    //     };

    //   return result;
    // }, {} as ImageTransforms),
  };

  return deleteUndefined(imageEntry);
};

export const parseImageMany = (maybeImage: MaybeArrayOf<ImageFragmentTypes>) => {
  const imageArr = makeNonNullableArray(maybeImage);
  return makeNonNullableArray(imageArr.map(parseImage));
};

export const isSvg = (image?: Maybe<ImageEntry>) => {
  return image?.mimeType === 'image/svg+xml';
};

export const parseImageFragment = createFragmentArrayParser(IMAGE_FRAGMENT, (data) =>
  data ? parseImage(data) : null
);

export const parseImageManyFragment = createFragmentArrayParser(IMAGE_FRAGMENT, (data) =>
  data ? parseImageMany(data) : null
);

import { EVENT_PRICE_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { toString } from '@liquorice/utils';
import { EventPriceCardFragment } from '__generated__/graphql';

type EventPrice = {
  title?: string;
  price?: string;
};

export const parseEventPrice = (data: Maybe<EventPriceCardFragment>): EventPrice | null => {
  if (!data) return null;

  const { title, maxPrice, minPrice } = data;

  // If both min and max prices are present, display them as a range
  // If only one is present, display that price
  const price = `${minPrice ? minPrice : ''}${minPrice && maxPrice ? ' - ' : ''}${
    maxPrice ? maxPrice : ''
  }`;

  return {
    title: toString(title),
    price,
  };
};

export const parseEventPriceFragment = createFragmentArrayParser(
  EVENT_PRICE_CARD_FRAGMENT,
  (items) => {
    return items.map((item) => parseEventPrice(item));
  }
);

import { PAGE_BRANCH_FRAGMENT } from '@/gql/fragments/entries.gql';
import { createFragmentParser } from '@liquorice/gql-utils';
import {
  makeNonNullableArray,
  maybeGet,
  parseHref,
  toId,
  toString,
  toTypedElementArray,
} from '@liquorice/utils';
import { PageBranchFragment, PageStandardEntryFragment } from '__generated__/graphql';
import { NavMenuItem } from '../navigation';

export const parsePagesTreeBranch = (
  maybeTree: Maybe<PageBranchFragment>,
  depth = 1
): NavMenuItem | null => {
  if (!maybeTree) return null;

  const { title, uri, id } = maybeTree;
  const children = (maybeGet(maybeTree, 'children') ?? []) as Maybe<PageBranchFragment>[];

  return {
    type: 'link',
    title: toString(title),
    href: parseHref(uri).href ?? '',
    id: toId(id),
    depth,
    subItems: makeNonNullableArray(children.map((v) => parsePagesTreeBranch(v, depth + 1))),
  };
};

export const parsePagesTreeBranchFragment = createFragmentParser(PAGE_BRANCH_FRAGMENT, (data) => {
  return parsePagesTreeBranch(data);
});

export const parsePagesTree = (maybeTree: Maybe<PageStandardEntryFragment['pagesBranch']>) => {
  const items = toTypedElementArray(maybeTree);
  return items ? items.map((v) => parsePagesTreeBranchFragment(v)) : [];
};

export const parseAncestors = (data: Maybe<PageStandardEntryFragment['ancestors']>) => {
  return makeNonNullableArray(data).map((v) => ({
    id: toId(v.id),
    title: toString(v.title),
    uri: parseHref(v.uri).href ?? '',
  }));
};

import { gql } from '__generated__';

export const SOCIAL_LINKS_FRAGMENT = gql(`
  fragment socialLinks on socialLinks_Entry {
    __typename
    facebook
    twitter
    instagram
  }
`);

export const ADDRESS_BASE_FRAGMENT = gql(`
  fragment address on Address {
    __typename
    addressLine1
    addressLine2
    addressLine3
    administrativeArea
    countryCode
    organization
    organizationTaxId
    postalCode
    sortingCode
    latitude
    locality
    longitude
    language
    dependentLocality
  }
`);

'use client';

import { SvgIconProps, SvgIconTypeMap } from '@/components/ui';
import { SprinklesProps, useSprinklesProps } from '@/theme';

import Error from '@mui/icons-material/Error';
import Facebook from '@mui/icons-material/Facebook';
import Heart from '@mui/icons-material/Favorite';
import HeartOutlined from '@mui/icons-material/FavoriteBorder';
import Instagram from '@mui/icons-material/Instagram';
import Menu from '@mui/icons-material/Menu';
import Twitter from '@mui/icons-material/Twitter';

import { SvgIcon } from '@mui/material';
import * as style from './Icon.css';

import Alert from '../../../public/svg/Alert.svg';
import AllEvents from '../../../public/svg/AllEvents.svg';
import Calendar from '../../../public/svg/Calendar.svg';
import Cart from '../../../public/svg/Cart.svg';
import Check from '../../../public/svg/Check.svg';
import Close from '../../../public/svg/Close.svg';
import Download from '../../../public/svg/Download.svg';
import External from '../../../public/svg/External.svg';
import Home from '../../../public/svg/Home.svg';
import Link from '../../../public/svg/Link.svg';
import Minus from '../../../public/svg/Minus.svg';
import Plus from '../../../public/svg/Plus.svg';
import Search from '../../../public/svg/Search.svg';
import Ticket from '../../../public/svg/Ticket.svg';
import Upload from '../../../public/svg/Upload.svg';
import User from '../../../public/svg/User.svg';
import ChevronLeft from '../../../public/svg/ChevronLeft.svg';
import ChevronRight from '../../../public/svg/ChevronRight.svg';
import ChevronUp from '../../../public/svg/ChevronUp.svg';
import ChevronDown from '../../../public/svg/ChevronDown.svg';
import Logo from '../../../public/svg/Logo.svg';
// import Star from '../../../public/svg/StarFull.svg';
// import StartOutlined from '../../../public/svg/StarHalf.svg';

export const appIcons = {
  menu: Menu,
  error: Error,
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  heart: Heart,
  heartOutlined: HeartOutlined,
} as const;

export const customIcons = {
  logo: Logo,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  home: Home,
  download: Download,
  close: Close,
  shoppingCart: Cart,
  calendar: Calendar,
  link: Link,
  check: Check,
  search: Search,
  plus: Plus,
  minus: Minus,
  ticket: Ticket,
  upload: Upload,
  profile: User,
  alert: Alert,
  allEvents: AllEvents,
  external: External,
};

export const icons = { ...appIcons, ...customIcons } as const;

export type AppIcons = typeof appIcons;
export type AppIcon = AppIcons[AppIconName];
export type AppIconName = keyof AppIcons;

export type CustomIcons = typeof customIcons;
export type CustomIcon = CustomIcons[CustomIconName];
export type CustomIconName = keyof CustomIcons;

export type Icons = typeof icons;
export type Icon = Icons[IconNames];
export type IconNames = AppIconName | CustomIconName;
export type { IconNames as IconName };

export type IconProps<
  D extends React.ElementType = SvgIconTypeMap['defaultComponent'],
  P = NoProps
> = SvgIconProps<
  D,
  {
    name: IconNames;
    className?: string;
  } & SprinklesProps &
    P
>;
const Icon = ({ name, ...rest }: IconProps) => {
  const Component = icons[name];
  const props = useSprinklesProps(rest);

  const sx = {
    fontSize: style.iconVars.fontSize,
    ...props.sx,
  };

  // If name equals the key of any of the customIcons, render the custom icon
  if (name in customIcons)
    return (
      <SvgIcon
        fontSize="inherit"
        // Causes issues with some svgs
        // inheritViewBox={true}
        viewBox="0 0 32 32"
        {...props}
        sx={sx}>
        <Component />
      </SvgIcon>
    );

  return <Component fontSize="inherit" {...props} sx={sx} />;
};

export default Icon;
